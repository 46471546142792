






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import JsonViewer from 'vue-json-viewer';
import { ATCODES } from '../utils/at-codes';
import { aptweak_categories, at_cats } from '../utils/aptweak-categories';
import {
  APP_KEYWORDS_FULL,
  SEARCH_BY_APP,
  SEARCH_BY_KEYWORD,
  SEARCH_FOR_APPS,
  SEARCH_FOR_COMPETES,
  GET_CATEGORY_TOP,
  KEYWORD_RECOMENDS,
  GET_RECOM_LIST,
  UPDATE_CHECKLIST,
  SBA_REPORT,
} from '../utils/apptweakqueries';
import DecisionAlert from '../components/DecisionAlert.vue';
import SelectAdGroup from '../components/keywords/SelectAdGroup.vue';
import JsonEditor from '../components/keywords/JsonEditor.vue'
import { Kochava } from 'kochava';
import AddKeysKai from '../components/keywords/AddKeysKai.vue';
import { LSS } from '@core/services/localstorage.service';
import { ADD_KEYWORDS, SAVE_MODEL, DELETE_MODEL } from '../utils/apollo-mutations';
import { v4 as uuidv4 } from 'uuid';
// import io from 'socket.io-client';
import { API_WS_URL } from '@config';
import { updateCLF } from '../utils/util';
import { KEYWORDS_PER_TRY } from '@config';
import InfoAlert from '../components/InfoAlert.vue';
import { PROCESS_KWGEN_MODEL, LIST_MODELS,LIST_SUGGESTIVE_MODELS, GET_AI_CREDITS, GENERATE_AI_KEYWORDS_KAI } from 'src/utils/apollo-queries';
import { decodeToken } from '@shared/utils/commom.util';
import { isReadOnly} from '../utils/util';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import SyncPoup from './../components/SyncPoup.vue'
import XLSX from 'xlsx';
enum CriteriaOptions {
  ALL = 'ALL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IN_RANGE = 'IN_RANGE',
  EQUALS = 'EQUALS',
}

export default Vue.extend({
  components: {
    SyncPoup,
    SelectAdGroup,
    JsonEditor,
    AddKeysKai,
    DecisionAlert,
    InfoAlert,
    JsonViewer,
    PermissionReadOnlyPopup
  },
  props: {
    kochava: Kochava,
  },
  computed: {
    isKochavaAccount: {
      cache: false,
      get() {
        const user = decodeToken(LSS.token);
        return user && user.Email && user.Email.includes('@kochava.com');
      }
    },
    showFilters: {
      cache: false,
      get() {
        return this.appTitle && this.tab == 0
      }
    },
    
  campaignGroupRules: {
      cache: false,
      get() {
        return [(v) => !!v || "This field is required"];
      },
    },
    alphanumericRule: {
      cache: false,
      get() {
        return [
          v => !!v,
          v => !/[^a-zA-Z0-9 ]/g.test(v) || "Only alphanumeric characters are allowed."
        ]
      }
    },
    modelName: {
      cache: false,
      get() {
        try {
          const model = JSON.parse(this.openAiModel || "{}");
          
          return model && model.properties && model.properties.name
        } catch(err) {
          return '';
        }
      }
    },
    visibleTopApp:{
      get() 
      {
          return this.catTop.slice((this.page3 - 1)* 100, this.page3* 100)
      }
    },
    items: {
      cache: false,
      get() {
        const user = decodeToken(LSS.token);
        return [
          { tab: this.$t('kai.searchByApp'), content: 'Search by App', id: 1 },
          {
            tab: this.$t('kai.searchByKey'),
            content: 'Search by Keywords',
            id: 2,
          },
          {
            tab: this.$t('kai.searchForCompetes'),
            content: 'Competitors',
            id: 3,
          },
          { tab: this.$t('kai.categoryTop'), content: 'Category Top', id: 4 },

           { 
            tab: "AI Suggestions", 
           content: 'AI Suggestions', 
           id: 7 },

          //{ tab: this.$t('kai.searchAdsRecommends'), content: 'Search Ads Recommendations', id : 5  },
          ...user.Role === 'SUPERADMIN' ? [{ tab: "Manage AI Models", content: 'AI Keyword Generator', id : 6  }] : []
        ];
      },
    },
    matchTypes: {
      get() {
        return [this.$t('addKeys.matchExact'), this.$t('addKeys.matchBroad')];
      },
    },

    catTypes: {
      get() {
        return [
          { title: this.$t('kai.free'), id: 'free' },
          { title: this.$t('kai.paid'), id: 'paid' },
        ];
      },
    },
    
    headers: {
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'plus',
            sortable: false,
          },
          // { text: 'S.No', value: 'sno' },
          {
            text: this.$t('kai.keywords'),
            value: 'name',
            tooltip: this.$t('kai.tooltip.keyText'),
          },
          //{ text: 'Imp Share', value: 'impshare', default: 'NA' },
          //{ text: 'Others', value: 'others', default: 'NA' },
          {
            text: this.$t('kai.organicRank'),
            value: 'organicRank',
            default: 'NA',
            tooltip: this.$t('kai.tooltip.organicRank'),
          },
          {
            text: this.$t('kai.popularity'),
            value: 'popularity',
            default: 'NA',
            tooltip: this.$t('kai.tooltip.popularity'),
          },
          {
            text: this.$t('kai.totalApps'),
            value: 'totalApps',
            default: 'NA',
            tooltip: this.$t('kai.tooltip.totalApps'),
          },
        ];
      },
    },
    recomheaders: {
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'plus',
            sortable: false,
          },
          // { text: 'S.No', value: 'sno' },
          { text: this.$t('kai.keywords'), value: 'name' },
          {
            text: this.$t('kai.popularity'),
            value: 'popularity',
            default: 'NA',
          },
          { text: this.$t('kai.totalApps'), value: 'totalApps', default: 'NA' },
          {
            text: this.$t('kai.monthlyDownloads'),
            value: 'totalDownloads',
            default: 'NA',
          },
        ];
      },
    },
    appheaders: {
      get() {
        return [
          // { text: 'S.No', value: 'sno' },
          {
            text: this.$t('kai.app'),
            value: 'appDetails',
            tooltip: this.$t('kai.tooltip.appDetails'),
          },
          //{ text: 'Imp. Share', value: 'is', default: 'NA' },
          {
            text: this.$t('kai.organicRank'),
            value: 'organicRank',
            default: '-',
            tooltip: this.$t('kai.tooltip.organicRankApp'),
          },
          //{ text: this.$t('kai.totalPaidKey'), value: 'noOfKeywords', default: 'NA' },
          {
            text: this.$t('kai.catName'),
            value: 'category',
            tooltip: this.$t('kai.tooltip.category'),
          },
        ];
      },
    },
    compHeaders: {
      get() {
        return [
          // { text: 'S.No', value: 'sno' },
          {
            text: this.$t('kai.app'),
            value: 'appDetails',
            tooltip: this.$t('kai.tooltip.appDetails'),
          },
          {
            text: this.$t('kai.compRating'),
            value: 'rating',
            tooltip: this.$t('kai.tooltip.rating'),
          },
          {
            text: this.$t('kai.compVersion'),
            value: 'version',
            tooltip: this.$t('kai.tooltip.version'),
          },
          {
            text: this.$t('kai.compPrice'),
            value: 'price',
            tooltip: this.$t('kai.tooltip.price'),
          },
          {
            text: this.$t('kai.compGenres'),
            value: 'genres',
            tooltip: this.$t('kai.tooltip.genres'),
          },
        ];
      },
    },
    catTopHeaders: {
      get() {
        return [
          // { text: 'S.No', value: 'sno' },
          {
            text: this.$t('kai.app'),
            value: 'appDetails',
            tooltip: this.$t('kai.tooltip.appDetails'),
          },
          {
            text: this.$t('kai.compRating'),
            value: 'rating',
            tooltip: this.$t('kai.tooltip.rating'),
          },
          {
            text: this.$t('kai.compPrice'),
            value: 'price',
            tooltip: this.$t('kai.tooltip.price'),
          },
          {
            text: this.$t('kai.compGenres'),
            value: 'genres',
            tooltip: this.$t('kai.tooltip.genres'),
          },
        ];
      },
    },

    selCatType: {
      get() {
        return this.selCatTypeTemp;
      },
      set(v) {
        this.selCatTypeTemp = v;
      },
    },
  },
  data() {
    return {
      attrs:{},
      on:{},
      selectedProfile:'',
      renderUpdateVar: true,
      aiCLientId: null,
      sortCompBy: 'none',     
      sortCompDesc: false,
      sortTopBy:'none',
      sortTopDesc: false,
      sortKeyBy:'none',
      sortKeyDesc: false,
      KeyAnalysisTab: undefined,
      aiModelForm: false,
      callingSuggestiveAi: false,
      remainingUsage: null,
      listItems: {},
      listItemDialog: {},
      usageLimit: null,
      refillsOn: null,
      showDownloadSBAReportProgress: false,
      renderedInputs: [],
      aiInputsModel: {},
      allowedInputs: [],
      allowedInputsModel: {},
      appFromModelInput: null,
      permissionReadOnlyMetadata:{},
      aimItems: [],
      aiModels: [],
      aiInputs:[],
      selectedAiModels:null,
      listSuggestiveModels:[],
      selectedOptions: [],
      textInput: [],
      modelURL:[],
      urlValidationStatus:{},
      ticks: {
        label: (value) => value * 100,
        snap: true,
        marks: [0, .25, .50, .75, 1],
      },
      savingModel: false,
      aimLoading: false,
      decisionAlertData: {},
      decisionAlertFlag: false,
      noAppsSuggestionAiTab: false,
      aimHeaders: [
        { text: 'S. No.', value: 'sno' },
        { text: 'Model Name', value: 'modelName' },
        { text: 'Public', value: 'isPublic' },
        { text: 'Last Processed', value: 'updatedOn' },
        { text: 'Restore', value: 'restore' },
        { text: 'Last Run Log', value: 'view' },
        { text: 'Download CSV', value: 'csv' },
        { text: 'Delete', value: 'delete' },
      ],
      expand1: 1,
      inputJson: "",
      outputJson: '',
      modelData: {},
      modelProcessing: false,
      modelProgressText: '',
      openAiModel: '',
      isModelValid: false,
      CountPref:10,
      CountPrefItems:[10,100,500,1000,2000,4000,8000,16000,32000],
      paginationItems:[25,50,100,200],
      addMessage:false,
      sugCompLayout: 0,
      catTopLayout:0,
      sabSortBy: 'organicRank',
      sabSortDesc: false,
      syncStatus: false,
      locale: 'en',
      cacheSC: false,
      cacheCT: false,
      catTopLoadingText: 'Loading apps... please wait',
      compLoadingText: 'Loading apps... please wait',
      sba: false,
      sbk: false,
      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage:
        'Fetching Data. You can close this and do something else and check status in the Data Requests window',
      selectedLang: null,
      langs: null,
      loadingAlertFlag: true,
      criteriaOptionsEnum: CriteriaOptions,
      loadingAlertText: 'Fetching data... Please stand by',
      opId: 0,
      selCatTypeTemp: { title: this.$t('kai.free'), id: 'free' },
      clientId: 0,
      compappIcon: '',
      compappTitle: '',
      searchTops: '',
      searchRecoms: '',
      recomsLoading: false,
      recoms: [],
      recomSearchLoading: false,
      searchedRecoms: [],
      recomSearchAppId: null,
      catTop: [],
      catTopLoading: false,
      loadingCatTypes: false,
      selCat: '6014',
      loadingCats: false,
      categories: [],
      compsLoading: false,
      compId: null,
      comps: [],
      searchCompeteId: null,
      prevSearchCompeteId: null,
      searchedCompetes: [],
      loadingCompetes: false,
      ownApps: [],
      progressText: 'Fetching data in the background...',
      progressErrCount: 0,
      progress: false,
      progressValue: 0,
      progressBufValue: 0,
      gotSoFar: 0,
      socket: null,
      outOf: 0,
      progressTextSBA: 'Fetching keywords for ',
      progressErrCountSBA: 0,
      progressSBA: false,
      progressValueSBA: 0,
      progressBufValueSBA: 0,
      gotSoFarSBA: 0,
      outOfSBA: 0,

      progressTextSBK: 'Fetching Apps for',
      progressErrCountSBK: 0,
      progressSBK: false,
      progressValueSBK: 0,
      progressBufValueSBK: 0,
      gotSoFarSBK: 0,
      outOfSBK: 0,

      fromDb: false,
      fromDbSBA: false,
      fromDbSBK: false,

      alertMessage: 'Our AI model doesn\'t have enough information on the selected app. Please select a similar app to continue.',

      aikeysFetched: false,
      fromDbAIK: false,
      progressTextAIK: 'Fetching keywords ',
      progressErrCountAIK: 0,
      progressAIK: false,
      progressValueAIK: 0,
      progressBufValueAIK: 0,
      gotSoFarAIK: 0,
      outOfAIK: 0,

      tags: [],
      tagSearch: '',
      language: '',
      device: '',
      selectedCountries: null,
      Countries: null,
      showAppNameSection: false,
      adgroupMetadata: null,
      selectAdgroupForKeywords: null,
      currency: 'USD',
      // matchTypes: [this.$t('addKeys.matchExact'), this.$t('addKeys.matchBroad')],
      addedKeywords: [],
      sourceKeywords: [],
      keyForm: false,
      amountRules: [
        (v) => !!v || 'Bid amount is required',
        (v) =>
          (v && v != 0 && v > 0) ||
          'Bid amount must be numeric and more than 0',
      ],
      showAddedKeyList: false,
      disableAppSearch: true,
      orgId: '',
      media: 50,
      numbers: 0,
      csName: '',
      drawer: true,
      timeProps: {
        outlined: true,
        dense: true,
      },
      options: {},
      options2: {},
      options3: {},
      options4: {},
      currPage: 0,
      currPage2: 0,
      country: [
        {
          name: 'India',
          code: 'in',
        },
        {
          name: 'United States',
          code: 'us',
        },
        {
          name: 'United Kingdom',
          code: 'uk',
        },
        {
          name: 'Australia',
          code: 'au',
        },
      ],
      mini: true,
      e1: 1,
      tab: null,
      date: new Date().toISOString().substr(0, 10),
      enddate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu5: false,
      quickAddDialog: false,
      quickAddFilterOptions: [
        {
          text: 'Greater Than',
          value: CriteriaOptions.GREATER_THAN,
        },
        {
          text: 'Less Than',
          value: CriteriaOptions.LESS_THAN,
        },
        {
          text: 'In Range',
          value: CriteriaOptions.IN_RANGE,
        },
      ],
      isOrganicRange: undefined,
      isPopularityRange: undefined,
      isAppsRange: undefined,

      quickAddModel: {
        // keys are taken from the response
        organicRank: {
          criteria: CriteriaOptions.ALL,
          value: null,
          from: null,
          to: null,
        },
        popularity: {
          criteria: CriteriaOptions.ALL,
          value: null,
          from: null,
          to: null,
        },
        totalApps: {
          criteria: CriteriaOptions.ALL,
          value: null,
          from: null,
          to: null,
        },
      },

      // Data table
      loadingKeys: false,
      loadingAIKeys: false,
      loadingApps: false,
      search: '',
      popularityRange: [0, 100],
      organicRankRange: [0, 10],
      organicRankRangeMax: 10,
      totalAppsRange: [0, 1000],
      totalAppsRangeMax: 1000,
      keyFilter: '',
      searchKeys: '',
      searchAIKeys: '',
      searchApps: '',
      searchedApps: [],
      loading: false,
      selectedApp: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 25,
      itemsPerPageAI: 250,
      noDataTextKeys: this.$t('kai.showDataText'),
      page2: 1,
      pageCount2: 0,
      page3: 1,
      pageCount3: 0,
      page4: 1,
      pageCount4: 0,
      itemsPerPage2: 100,
      noDataTextApps: this.$t('kai.showDataText'),
      recomHeaders: [
        {
          text: '',
          align: 'start',
          value: 'plus',
          sortable: false,
        },
        // { text: 'S.No', value: 'sno' },
        { text: this.$t('kai.keyword'), value: 'keyword' },
        { text: this.$t('kai.popularity'), value: 'popularity' },
      ],
      allApps: [],
      applicationInsights: [],
      keywordDetails: {
        popularity: 0,
        noOfApps: 0,
        appsRunningAds: 0,
        chance: 0,
      },
      searchKey: '',
      startDate: '2021-03-01',
      endDate: new Date().toISOString().substr(0, 10),
      appIds: [],
      appIdsLimited: [],
      allRanks: [],
      searchApp: '',
      searchCompete: '',
      searchTextForCompete: '',
      searchTextForSBA: '',
      searchRecom: '',
      searchAppId: 604044220,
      prevSearchAppId: 604044220,
      appIcon: '',
      appTitle: '',
      appSubTitle: '',
      appTPK: 0,
      appAC: 0,
      allKeywords: [],
      fewKeywords: [],
      keywordInsights: [],
      keywordAIInsights: [],
      filteredkeywordInsights: [],
      filteredAIkeywordInsights: [],
      allAppKeyRanks: [],
      searchAppFull: null,
      recomappIcon: '',
      recomappTitle: '',
      recomappSubTitle: '',
      recomappTPK: 0,
      recomappAC: 0,
      recomkeywordInsights: [],
      recomfilteredkeywordInsights: [],
      recomloadingKeys: false,
      recomshowAppNameSection: false,
      recomoutOf: 0,
      quickAddForm: false,
      toOrganicRules: [
        (v) =>
          +v > +this.quickAddModel.organicRank.from ||
          'To value should be greater than "From" value',
      ],
      fromOrganicRules: [
        (v) =>
          +v < +this.quickAddModel.organicRank.to ||
          'From value should be less than "To" value',
      ],
      fromPopularityRules: [
        (v) =>
          +v < +this.quickAddModel.popularity.to ||
          'From value should be less than "To" value',
      ],
      toPopularityRules: [
        (v) =>
          +v > +this.quickAddModel.popularity.from ||
          'To value should be greater than "From" value',
      ],
      fromTotalAppsRules: [
        (v) =>
          +v < +this.quickAddModel.totalApps.to ||
          'From value should be less than "To" value',
      ],
      toTotalAppsRules: [
        (v) =>
          +v > +this.quickAddModel.totalApps.from ||
          'To value should be greater than "From" value',
      ],
    };
  },
  methods: {
    async forceUpdate() {
      this.renderUpdateVar = false;
      
      await this.$nextTick()
      
      this.renderUpdateVar = true;
    },  
    listItemsValid(input) {
        return !this.listItems[input.promptMacro].find(item => !item.value || /[^a-zA-Z0-9 ]/g.test(item.value))
    },
    customSort(items, sortBy, sortDesc) {
        if(sortBy=="appDetails" || sortBy=="genres"){
        let key = '';
              if(sortBy=="appDetails"){
                    key = "title";
              }else{
                    key = "genres";
              }
              
              if (sortDesc[0] === false) {
              
              
              return  items.sort((a, b) =>{
              
              return a.appDetails ? a.appDetails[key].localeCompare(b.appDetails[key]) :   a[key].localeCompare(b[key])
                    
              } );
              } else if (sortDesc[0] === true) {
              
              return items.sort((a, b) => {
                  return b.appDetails ? b.appDetails[key].localeCompare(a.appDetails[key]) :  b[key].localeCompare(a[key])
                
              } );
              }
        
      }else if(sortBy=="price"){
    
          return items.slice().sort((a, b) => {
            const priceA = parseFloat(a.price.replace('$', ''));
              const priceB = parseFloat(b.price.replace('$', ''));
              return  sortDesc[0] === false ? priceA - priceB : priceB - priceA;
            
          });
        }else if(sortBy=="version"){
          if (sortDesc[0] === false) {
            return items.sort(this.compareVersions);

          }
          else if (sortDesc[0] === true) {
          return  items.sort((a, b) => this.compareVersions(b, a));


          }
            
    
    }else{
          //other columns
          items.sort((a, b) => {
          for (let i = 0; i < sortBy.length; i++) {
            if (sortDesc[i]) {
              if (b[sortBy[i]] < a[sortBy[i]]) return -1;
              if (b[sortBy[i]] > a[sortBy[i]]) return 1;
            } else {
              if (a[sortBy[i]] < b[sortBy[i]]) return -1;
              if (a[sortBy[i]] > b[sortBy[i]]) return 1;
            }
            //  }
          
            
          }
          return 0;
        });

        return items;
      }   
    },
    compareVersions(a, b) {
      const versionA = a.version.split('.').map(Number);
      const versionB = b.version.split('.').map(Number);

      for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        const numA = versionA[i] || 0;
        const numB = versionB[i] || 0;

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        }
      }
      return 0;
    },
    appselectionChanged(title) {
      const app = this.searchedCompetes.find(a => a.title === title);
      
      this.appFromModelInput = app;
    },
    async getAiCreditsForUser() {
      try {
        const response = await this.$apollo
          .query({
            query: GET_AI_CREDITS,
          })
          this.remainingUsage = response.data.getCredits.remainingUsage;
          this.usageLimit = response.data.getCredits.usageLimit;
          this.refillsOn = response.data.getCredits.refillsOn;
    
      } catch(error) {
        // console.log(error)
      }
    },
    tooltipFormatter(value) {
      return `${value}%`;
    },
    validateURL(url, index) {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      const isValidURL = urlPattern.test(url);
      this.urlValidationStatus[index] = isValidURL ? '' : 'Please enter a valid URL starting with "http://" or "https://"';
    },
    async downloadSBAReport() {
      this.showDownloadSBAReportProgress = true
      try {
        const response = await this.$apollo
        .query({
          query: SBA_REPORT,
          variables: {
            appId: this.searchAppId,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })          
        const kArr = JSON.parse(response.data.searchByAppReport.data)
        const reducedKArr = kArr.map(({difficulty, totalDownloads, isBranded, ...keep}) => keep)
        // console.log(kArr)
        let fileName = 'kw-search-app-report' + '.csv';
        const data = XLSX.utils.json_to_sheet(reducedKArr)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        XLSX.writeFile(wb,fileName)
        this.showDownloadSBAReportProgress = false;
        this.$notify.success('Downloaded reports successfully.')
      } catch(error) {
        // console.log(error)
        this.showDownloadSBAReportProgress = false;
        this.$notify.error('Something went wrong while downloading keyword analysis app search report.')
      }
    },
    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
   
    downloadCsv(item) {
      const encodedUri = encodeURI(item.csv);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", item.modelName.split(' ').join('_') + ".csv");
      document.body.appendChild(link); // Required for FF
          
      link.click();
    },
    exportAiKeywords() {
      
        const csv = [['Keyword', 'Tier', 'Timestamp', 'Log']];
      const kw = _.cloneDeep(typeof this.outputJson === 'string' ? JSON.parse(this.outputJson).keywords : this.outputJson.keywords);
      const logMessages = _.cloneDeep(this.modelData.logMessages);
      delete kw.nontiered;
      
      for (const tier in kw) {
        kw[tier].forEach(keyword => {
          csv.push([keyword, tier, '', ''])
        });
      }
    
      (logMessages as any)?.forEach(item => {
        csv.push(['', '', item.timestamp, item.message.split('\n').join(',')]);
      });
    
      const csvContent = 'data:text/csv;charset=utf-8,'
        + csv.map(e => e.join(',')).join('\n');
      
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.modelName.split(' ').join('_') + ".csv");
      document.body.appendChild(link); // Required for FF
          
      link.click();
    },
    localDate(date) {
      if(!date) return
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    decisionOkAction(event) {
      this.deleteModel(event.modelName);
    },
    deleteModelAsk(modelName) {
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        modelName,
        now: Date.now()
      }
    },
    getPublicName(item) {
      try {
        const model = JSON.parse(item.model);
        
        if(model && model.public && model.public.enabled) return '"' + model.public.displayName + '"';
        
        return '(private)'
      } catch(error) {
        console.log(error);
      }
    },
    async listModels() {
      this.aimLoading = true;
      try {
        const response = await this.$apollo
          .query({
            query: LIST_MODELS
          })
          
      this.aimItems = response.data.listModels;
      
      this.aimItems = this.aimItems.reduce((acc, element) => {
        try {
          const model = JSON.parse(element.model);
          if (model && model.public && model.public.enabled) {
            return [element, ...acc];
          }
        } catch(error) {
          console.log(error);
        }
        return [...acc, element];
      }, []);
      
      this.aimLoading = false;
      } catch(error) {
        this.aimLoading = false;
      }
    },
    
    async listModelsDropDown() {

      this.aimLoading = true;
      try {
        const response = await this.$apollo
          .query({
            query: LIST_SUGGESTIVE_MODELS
          })
        const result = response.data.listSuggestiveModels
        this.listSuggestiveModels.push(...result);

        //fetch AI models
        // Loop through the docs array
        result.forEach((doc) => {
          // Parse the JSON string in the 'public' property to get the object
          const modelObj = JSON.parse(doc.model);
          const publicValue = modelObj.public;
          //render inputs
          this.aiInputs = publicValue.inputs;
          this.aiModels.push({
            title: publicValue.displayName,
            id: doc._id,
          });
          // Check if 'enabled' is true

        });
        this.aimLoading = false;
        
        this.selectedAiModels = this.aiModels[0] ? this.aiModels[0].id : null;
        
        if(this.selectedAiModels) this.generateElements()
        
      } catch (error) {
        console.log("error", error);
        this.aimLoading = false;
      }
    },
    async saveModel() {
      this.savingModel = true;
      try {
        const data = ({
          model: this.openAiModel,
          keywords: this.modelData.data,
          logMessages: this.modelData.logMessages && JSON.stringify(this.modelData.logMessages)
        })
        const result = await this.$apollo.mutate({
          mutation: SAVE_MODEL,
          variables: data,
        });
        if (result.data.saveModel.successMessage) {
          this.$notify.success("Model saved.");
          this.listModels();
        } else {
          this.$notify.error('An error occurred while trying to save model');
        }
        
      } catch {
      }
      this.savingModel = false;
    },
    async deleteModel(modelName) {
      try {
        const result = await this.$apollo.mutate({
          mutation: DELETE_MODEL,
          variables: {
            modelName
          },
        });
        if (result.data.deleteModel.successMessage) {
          this.$notify.success("Model deleted.");
          this.listModels();
          this.decisionAlertFlag = false;
          this.decisionAlertData = {}
        } else {
          this.$notify.error('An error occurred while trying to delete model');
        }
        
      } catch {
      }
    },
    viewModel(item) {
      this.outputJson = { keywords: item.keywords, log: item.logMessages.map(m => m.timestamp + ' : ' + m.message) };
      this.modelData = { data: typeof item.keywords !== 'string' ? JSON.stringify(item.keywords) : item.keywords, logMessages: item.logMessages }
      this.openAiModel = item.model;
    },
    restoreModel(item) {
      this.inputJson = typeof item.model === "string" ? item.model : JSON.stringify(item.model);
    },
    processModel() {
      this.modelProcessing = true;
      this.modelProgressText = 'Start'
      this.outputJson = '';
      this.modelData = {};
      try {
        this.$apollo
        .query({
          query: PROCESS_KWGEN_MODEL,
          variables: {
            model: this.openAiModel,
          }
        })
        .catch((error) => {
          this.modelProcessing = false; 
          console.log(error);
        });
      } catch(error) {
        this.modelProcessing = false; 
      }
    },
    
    stopProcessing() {
      this.modelProcessing = false;
    },

    suggestiveAiJsonModel(event) {
      this.openAiModel = event.code;
      this.isModelValid = event.valid;
      this.inputJson = ''
      this.outputJson = ''
      this.modelData = {};
    },
    
    popupGuided(){
      return this.$router.push('/keyword-optimization');

    },
    clearKeywordSearch() {
      this.applicationInsights = []
      this.keywordDetails = {
        popularity: 0,
        noOfApps: 0,
        appsRunningAds: 0,
        chance: 0,
      }
      this.searchKey = ''
    },
    organicCriteriaSelected(value) {
      this.isOrganicRange = value === CriteriaOptions.IN_RANGE || value === CriteriaOptions.ALL;
      switch (value) {
        case CriteriaOptions.ALL:
          this.quickAddModel['organicRank']['value'] = null
          this.quickAddModel['organicRank']['from'] = 0
          this.quickAddModel['organicRank']['from'] = this.organicRankRangeMax
          break;
        case CriteriaOptions.GREATER_THAN:
          this.quickAddModel['organicRank']['value'] = this.organicRankRange[0]
          this.quickAddModel['organicRank']['from'] = null
          this.quickAddModel['organicRank']['to'] = null
          break;
        case CriteriaOptions.LESS_THAN:
          this.quickAddModel['organicRank']['value'] = this.organicRankRange[1]
          this.quickAddModel['organicRank']['from'] = null
          this.quickAddModel['organicRank']['to'] = null
          break;
        case CriteriaOptions.EQUALS:
          this.quickAddModel['organicRank']['value'] = this.organicRankRange[0]
          this.quickAddModel['organicRank']['from'] = null
          this.quickAddModel['organicRank']['to'] = null
          break;
        case CriteriaOptions.IN_RANGE:
          this.quickAddModel['organicRank']['value'] = null
          this.quickAddModel['organicRank']['from'] = this.organicRankRange[0]
          this.quickAddModel['organicRank']['to'] = this.organicRankRange[1]
          break;
        default:
          break;
      }
    },
    popularityCriteriaSelected(value) {
      this.isPopularityRange = value === CriteriaOptions.IN_RANGE || value === CriteriaOptions.ALL;
      switch (value) {
        case CriteriaOptions.ALL:
          this.quickAddModel['popularity']['value'] = null
          this.quickAddModel['popularity']['from'] = 0
          this.quickAddModel['popularity']['from'] = 100
          break;
        case CriteriaOptions.GREATER_THAN:
          this.quickAddModel['popularity']['value'] = this.popularityRange[0]
          this.quickAddModel['popularity']['from'] = null
          this.quickAddModel['popularity']['to'] = null
          break;
        case CriteriaOptions.LESS_THAN:
          this.quickAddModel['popularity']['value'] = this.popularityRange[1]
          this.quickAddModel['popularity']['from'] = null
          this.quickAddModel['popularity']['to'] = null
          break;
        case CriteriaOptions.EQUALS:
          this.quickAddModel['popularity']['value'] = this.popularityRange[0]
          this.quickAddModel['popularity']['from'] = null
          this.quickAddModel['popularity']['to'] = null
          break;
        case CriteriaOptions.IN_RANGE:
          this.quickAddModel['popularity']['value'] = null
          this.quickAddModel['popularity']['from'] = this.popularityRange[0]
          this.quickAddModel['popularity']['to'] = this.popularityRange[1]
          break;
        default:
          break;
      }
    },
    appsCriteriaSelected(value) {
      this.isAppsRange = value === CriteriaOptions.IN_RANGE || value === CriteriaOptions.ALL;
      switch (value) {
        case CriteriaOptions.ALL:
          this.quickAddModel['totalApps']['value'] = null
          this.quickAddModel['totalApps']['from'] = 0
          this.quickAddModel['totalApps']['from'] = this.totalAppsRangeMax
          break;
        case CriteriaOptions.GREATER_THAN:
          this.quickAddModel['totalApps']['value'] = this.totalAppsRange[0]
          this.quickAddModel['totalApps']['from'] = null
          this.quickAddModel['totalApps']['to'] = null
          break;
        case CriteriaOptions.LESS_THAN:
          this.quickAddModel['totalApps']['value'] = this.totalAppsRange[1]
          this.quickAddModel['totalApps']['from'] = null
          this.quickAddModel['totalApps']['to'] = null
          break;
        case CriteriaOptions.EQUALS:
          this.quickAddModel['totalApps']['value'] = this.totalAppsRange[0]
          this.quickAddModel['totalApps']['from'] = null
          this.quickAddModel['totalApps']['to'] = null
          break;
        case CriteriaOptions.IN_RANGE:
          this.quickAddModel['totalApps']['value'] = null
          this.quickAddModel['totalApps']['from'] = this.totalAppsRange[0]
          this.quickAddModel['totalApps']['to'] = this.totalAppsRange[1]
          break;
        default:
          break;
      }
    },
    closeProgress() {
      if (this.socket) this.socket.disconnect();
      this.progress = false;
    },
    recomSearchChanged(e) {
      this.recomcloseApp();
      if (e !== null && e !== '') {
        this.recomSearchAppId = e;
        this.getRecomKeysForApp(e);
      }
    },
    catChanged(e) {
      //console.log(e)
      this.getCategoryTop();
    },
    catTypeChanged(e) {
      this.getCategoryTop();
    },
    compChanged(e) {
      // console.log(e)
      this.comps = [];
      this.compappTitle = '';
      this.compappIcon = '';
      if (e && e.id) {
        this.compappTitle = e.title;
        this.compappIcon = e.icon;
        this.searchCompeteId = e;
        this.searchForCompetes(e);
      } else if (e) {
        this.searchTextForCompete = e;
      } else {
      }
    },
    sbaChanged(e) {
      // console.log(e)
      // return
      //this.closeApp();
      if (e && e.id) {
        this.appTitle = e.title;
        this.appIcon = e.icon;
        this.appSubTitle = e.subtitle || '';
        this.searchAppId = e.id;
        this.getKeysForApp();
      } else if (e) {
        this.searchTextForSBA = e;
      } else {
      }
    },
    compAppClicked(title, id, icon) {
      //console.log(title,id,icon)
      this.closeApp();
      this.searchAppId = id;
      this.searchApp = title;
      this.appTitle = title;
      this.appIcon = icon;
      this.setKaiQuery(0, id, icon, title, LSS.kaiQuery.keyword);
      this.searchAppFull = {
        icon: icon,
        id: parseInt(id),
        title: title
      };
      this.tab = 0;
      const fakeEvent = {
        keyCode: 13,
      };
      this.getKeysForApp();
    },
    getCompInsights(e) {
      // console.log(e);
    },
    updateTags() {
      //console.log('updateTags', this.tags);
      this.applyFilter(0);
    },
    getKeysForApp() {
      this.progressSBA = false;
      this.fromDbSBA = true;
      this.clientId = uuidv4();
      this.gotSoFarSBA = 0;
      this.outOfSBA = 0;
      this.progressValueSBA = 0;
      this.progressBufValueSBA = 0;
      this.progressErrCountSBA = 0;

      this.loadingKeys = true;
      this.keywordInsights = [];
      this.filteredkeywordInsights = [];
      this.popularityRange[0] = 0;
      this.popularityRange[1] = 100;
      this.popularityRange = [0, 100];
      this.organicRankRange[0] = 0; //put this on search button click
      this.organicRankRange[1] = 10;
      this.organicRankRange = [0, this.organicRankRangeMax];
      this.totalAppsRange[0] = 0; //put this on search button click
      this.totalAppsRange[1] = 1000;
      this.totalAppsRange = [0, this.totalAppsRangeMax];
      this.tags = [];
      this.setKaiQuery(
        0,
        this.searchAppId,
        this.appIcon,
        this.appTitle,
        LSS.kaiQuery.keyword
      );

      this.$apollo
        .query({
          query: SEARCH_BY_APP,
          variables: {
            appId: this.searchAppId,
            title: this.appTitle || '',
            icon: this.appIcon || '',
            opId: 1,
            clientId: this.clientId,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            startDate: this.startDate,
            endDate: this.endDate,
            currPage: Date.now(),
            itemsPerPage: 10,
            CountPref:this.CountPref
          },
        })
        .then((data) => {
          // console.log(data.data);
          if (
            data.data.searchByApp == null ||
            data.data.searchByApp == undefined ||
            !data.data.searchByApp.keywords
          ) {
            this.loadingKeys = false;
            LSS.drsba = 0;
            this.progressSBA = false;
            this.noDataTextKeys = 'No data to show';
            return;
          }
          updateCLF('kaiApp', this);
          this.keywordInsights = data.data.searchByApp.keywords;
          // this.appTitle = data.data.searchByApp.details.title;
          // this.appIcon = data.data.searchByApp.details.icon;
          // this.appSubTitle = data.data.searchByApp.details.subtitle;
          this.appTPK = data.data.searchByApp.count;
          if(this.appTPK){
            this.showAppNameSection = true;
          }
           
          this.filteredkeywordInsights = data.data.searchByApp.keywords;
          this.filteredkeywordInsights.forEach((x) => {
            if (x.organicRank == 0) x.organicRank = 100001;
            if (x.popularity < 5) x.popularity = 5;
           // this.filteredkeywordInsights.push(x);
          });
          // console.log('sorting keys', this.appTPK)
          this.filteredkeywordInsights.sort(
            (a, b) => a.organicRank - b.organicRank
          );
          this.filteredkeywordInsights = Object.values(
                this.filteredkeywordInsights.reduce( (c, e) => {
                  if (!c[e.name]) c[e.name] = e;
                  return c;
                }, {})
              );
          this.loadingKeys = false;
          this.fromDbSBA = data.data.searchByApp.fromDB;
          if (!this.fromDbSBA) {
            this.progressSBA = true;
            LSS.drsba = this.searchAppId;
            this.gotSoFarSBA = 0;
            this.outOfSBA = this.appTPK;
            // this.progressValueSBA = (1000/this.appTPK)
            // this.progressBufValueSBA = this.progressValueSBA + (1000/this.appTPK)
            this.progressValueSBA = 0;
            this.progressBufValueSBA = 10;
            const c = {
              done: false,
              preText: 'Fetching',
              text: ' Keywords for App - ' + this.appTitle,
              path: 'keyword-auction-insights',
              query: 'app',
              icon: 'mdi-format-list-bulleted',
              disabled: true,
              progress: 0,
              total: this.appTPK,
              fetched: 0,
              clientId: this.clientId,
              appId: this.searchAppId,
              opId: 1,
              keyword: 'searching for an app',
            };
            // this.$root.$emit('addDataRequests', c)
            this.$root.$emit('get-dr', 'sba');
          } else {
            this.showAppNameSection = true;
            if (LSS.drsba === this.searchAppId) {
              setTimeout(() => {
                this.$root.$emit('get-dsrba', this.searchAppId);
              }, 500);
              this.progressSBA = true;
              return;
            }
            let set = false;
            if (LSS.dataRequests && LSS.dataRequests.length > 0) {
              LSS.dataRequests.forEach((e) => {
                if (e.appId === this.searchAppId && !e.done) {
                  LSS.drsba = this.searchAppId;
                  set = true;
                  setTimeout(() => {
                    this.$root.$emit('get-dsrba', this.searchAppId);
                  }, 500);
                  this.progressSBA = true;
                }
              });
              if (!set) {
                this.progressSBA = false;
                LSS.drsba = 0;
              }
            } else {
              this.progressSBA = false;
              LSS.drsba = 0;
            }
          }
          
          this.forceUpdate();
        })
        .catch((error) => {
          // console.log(error);
          LSS.drsba = 0;
          this.progress = false;
          this.loadingKeys = false;
          this.noDataTextKeys = 'No data to show';
        });
    },
    async getRecomKeysForApp() {
      // if(LSS.token && decodeToken(LSS.token).checkList){
      //   if(!(decodeToken(LSS.token).checkList.kaiRecom)) await this.updateCL('kaiRecom')
      // }
      if (this.socket) this.socket.disconnect();
      this.progress = false;
      this.fromDb = true;
      this.gotSoFar = 10;
      this.clientId = uuidv4();
      //console.log(this.clientId)
      this.outOf = 100;
      this.progressValue = 0;
      this.progressBufValue = 0;
      this.progressErrCount = 0;
      this.recomloadingKeys = true;
      this.recomkeywordInsights = [];
      this.recomfilteredkeywordInsights = [];

      this.socket = io(API_WS_URL);

      this.socket.on('connect', () => {
        //console.log(this.socket.id);
      });

      this.socket.on('error', (error) => {
        if (this.socket) this.socket.disconnect();
        this.progress = false;
        //console.log('Socket error');
      });

      this.socket.on('msgToClient', (message) => {
        //console.log(message)
        if (
          message.clientId != this.clientId ||
          message.opId != 3 ||
          message.appId != this.recomSearchAppId
        )
          return;
        //if(message.appId != this.recomSearchAppId) return
        //this.fromDb = message.fromDB
        if (message.data == 'over') {
          this.progress = false;
          this.socket.disconnect();
          return;
        }
        this.gotSoFar = this.gotSoFar + 10;
        this.progressValue = this.progressValue + 1000 / this.recomappTPK;
        this.progressBufValue = this.progressValue + 1000 / this.recomappTPK;
        if (message.err) {
          this.progressErrCount = this.progressErrCount + 10;
          return;
        }
        const keys = JSON.parse(message.data);
        //console.log(keys)
        //this.progressText = 'Fetching more keywords... '+gotSoFar.toString()+'/'+this.appTPK.toString(),
        keys.map((x) => {
          //console.log(x)
          this.recomfilteredkeywordInsights.push(x);
        });
      });

      this.socket.on('disconnect', () => {
        //this.progressText = 'Server disconnected...'
        this.progress = false;
        //console.log('Client Disconnected');
      });

      //this.socket.emit('msgToServer', 'Hello')

      this.$apollo
        .query({
          query: GET_RECOM_LIST,
          variables: {
            appId: this.recomSearchAppId,
            opId: 3,
            clientId: this.clientId,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            startDate: this.startDate,
            endDate: this.endDate,
            currPage: Date.now(),
            itemsPerPage: 10,
          },
        })
        .then((data) => {
          //console.log(data.data);
          if (
            data.data.getRecommendationList == null ||
            data.data.getRecommendationList == undefined ||
            !data.data.getRecommendationList.keywords
          ) {
            this.recomloadingKeys = false;
            this.noDataTextKeys = 'No data to show';
            return;
          }
          updateCLF('kaiRecom', this);
          this.recomkeywordInsights = data.data.getRecommendationList.keywords;
          this.recomappTitle = data.data.getRecommendationList.details.title;
          this.recomappIcon = data.data.getRecommendationList.details.icon;
          this.recomappSubTitle =
            data.data.getRecommendationList.details.subtitle;
          this.recomappTPK = data.data.getRecommendationList.count;
          this.recomshowAppNameSection = true;
          this.recomfilteredkeywordInsights =
            data.data.getRecommendationList.keywords;
          this.recomloadingKeys = false;
          this.outOf = this.recomappTPK;
          this.progressValue = 1000 / this.recomappTPK;
          this.progressBufValue = this.progressValue + 1000 / this.recomappTPK;
          this.fromDb = data.data.getRecommendationList.fromDB;
          if (!this.fromDb) {
            this.progress = true;
            const c = {
              done: false,
              preText: 'Fetching',
              text: ' Recommendations for App - ' + this.recomappTitle,
              path: 'keyword-auction-insights',
              query: 'recom',
              icon: 'mdi-format-list-bulleted',
              disabled: true,
              progress: 1000 / this.recomappTPK,
              total: this.recomappTPK,
              fetched: 10,
              clientId: this.clientId,
              appId: this.recomSearchAppId,
              opId: 3,
              keyword: 'searching for recommendations',
            };
            this.$root.$emit('addDataRequests', c);
          } else {
            this.progress = false;
            this.socket.disconnect();
          }
        })
        .catch((error) => {
          //console.log(error);
          this.progress = false;
          this.recomloadingKeys = false;
          this.noDataTextKeys = 'No data to show';
        });
    },
    getKeysForAppPagination(cp, perPage) {
      this.loadingKeys = true;
      this.$apollo
        .query({
          query: SEARCH_BY_APP,
          variables: {
            appId: this.searchAppId,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            startDate: this.startDate,
            endDate: this.endDate,
            currPage: cp,
            itemsPerPage: perPage,
            CountPref:this.CountPref
          },
        })
        .then((data) => {
          //console.log(data.data.searchByApp.keywords);
          if (
            data.data.searchByApp == null ||
            data.data.searchByApp == undefined
          ) {
            this.loadingKeys = false;
            this.noDataTextKeys = 'No data to show';
            return;
          }
          for (
            let index = 0;
            index < data.data.searchByApp.keywords.length;
            index++
          ) {
            const element = data.data.searchByApp.keywords[index];
            this.keywordInsights.push(element);
          }
          this.filteredkeywordInsights = this.keywordInsights;
          this.filteredkeywordInsights = Object.values(
                          this.filteredkeywordInsights.reduce( (c, e) => {
                            if (!c[e.name]) c[e.name] = e;
                            return c;
                          }, {})
                        );
          this.loadingKeys = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loadingKeys = false;
          this.noDataTextKeys = 'No data to show';
        });
    },
    getDataForKeyword(e) {
        // if(this.searchKey.length < 3){
        //   alert('Please enter atleast 3 characters');
        //   return;
        // }

        this.progressSBK = false;
        this.fromDbSBK = true;
        this.clientId = uuidv4();
        this.gotSoFarSBK = 0;
        this.outOfSBK = 100;
        this.progressValueSBK = 0;
        this.progressBufValueSBK = 0;
        this.progressErrCountSBK = 0;

        this.loadingApps = true;
        this.applicationInsights = [];
        this.keywordDetails.popularity = 0;
        this.keywordDetails.noOfApps = 0;
        this.keywordDetails.chance = 0;
        this.keywordDetails.appsRunningAds = 0;

        this.setKaiQuery(
          1,
          LSS.kaiQuery.appId,
          LSS.kaiQuery.icon,
          LSS.kaiQuery.title,
          this.searchKey.toLowerCase()
        );

        this.$apollo
          .query({
            query: SEARCH_BY_KEYWORD,
            variables: {
              keyword: this.searchKey.toLowerCase(),
              opId: 2,
              clientId: this.clientId,
              startDate: this.startDate,
              endDate: this.endDate,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              currPage: Date.now(),
              itemsPerPage: 1000,
            },
          })
          .then((data) => {
            //console.log(data.data);
            if (
              data.data.searchByKeyword == null ||
              data.data.searchByKeyword == undefined ||
              !data.data.searchByKeyword.apps
            ) {
              this.loadingApps = false;
              this.progressSBK = false;
              LSS.drsbk = null;
              this.noDataTextApps = 'No data to show';
              return;
            }
            updateCLF('kaiKeyword', this);
            this.applicationInsights = data.data.searchByKeyword.apps;
            this.applicationInsights.forEach((x) => {
              if (x.organicRank == 0) x.organicRank = 100001;
            });
            this.applicationInsights.sort(
              (a, b) => a.organicRank - b.organicRank
            );
            this.applicationInsights = Object.values(
                this.applicationInsights.reduce( (c, e) => {
                  if (!c[e.appId]) c[e.appId] = e;
                  return c;
                }, {})
              );
            this.keywordDetails.popularity =
              data.data.searchByKeyword.details.popularity;
            this.keywordDetails.noOfApps =
              data.data.searchByKeyword.details.noOfApps;
            this.keywordDetails.chance =
              data.data.searchByKeyword.details.chance;
            this.keywordDetails.appsRunningAds =
              data.data.searchByKeyword.details.appsRunningAds;
            this.loadingApps = false;
            this.fromDbSBA = data.data.searchByKeyword.fromDB;
            if (!this.fromDbSBA) {
              this.progressSBK = true;
              LSS.drsbk = this.searchKey.toLowerCase();
              this.gotSoFarSBK = 0;
              this.outOfSBK = this.keywordDetails.appsRunningAds;
              // this.progressValueSBK = ((500/this.keywordDetails.appsRunningAds))
              this.progressValueSBK = 0;
              // this.progressBufValueSBK = this.progressValueSBK + (500/this.keywordDetails.appsRunningAds)
              this.progressBufValueSBK = 5;
              const c = {
                done: false,
                preText: 'Fetching',
                text: ' Apps for keyword - ' + this.searchKey,
                path: 'keyword-auction-insights',
                query: 'key',
                icon: 'mdi-apps',
                disabled: true,
                progress: 0,
                total: this.keywordDetails.appsRunningAds,
                fetched: 0,
                clientId: this.clientId,
                appId: 'searching for a keyword',
                opId: 2,
                keyword: this.searchKey.toLowerCase(),
              };
              // this.$root.$emit('addDataRequests', c)
              this.$root.$emit('get-dr', 'sbk');
            } else {
              if (LSS.drsbk === this.searchKey.toLowerCase()) {
                setTimeout(() => {
                  this.$root.$emit('get-dsrbk', this.searchKey.toLowerCase());
                }, 500);
                this.progressSBK = true;
                return;
              }
              let set = false;
              if (LSS.dataRequests && LSS.dataRequests.length > 0) {
                LSS.dataRequests.forEach((e) => {
                  if (e.keyword === this.searchKey.toLowerCase() && !e.done) {
                    LSS.drsbk = this.searchKey.toLowerCase();
                    set = true;
                    setTimeout(() => {
                      this.$root.$emit(
                        'get-dsrbk',
                        this.searchKey.toLowerCase()
                      );
                    }, 500);
                    this.progressSBK = true;
                  }
                });
                if (!set) {
                  this.progressSBK = false;
                  LSS.drsbk = null;
                }
              } else {
                this.progressSBK = false;
                LSS.drsbk = null;
              }
            }
          })
          .catch((error) => {
            LSS.drsbk = null;
            this.progressSBK = false;
            this.loadingApps = false;
            this.noDataTextApps = 'No data to show';
          });
    },
    getDataForKeywordBefSeperatePop(e) {
      // const c = new VueSocketIO({
      //     debug: true,
      //     connection: 'http://localhost:8092'
      // })
      // console.log(c)

      if (e.keyCode == 13) {
        if (this.searchKey.length < 3) {
          alert('Please enter atleast 3 characters');
          return;
        }

        if (this.socket) this.socket.disconnect();
        this.progress = false;
        this.fromDb = true;
        this.gotSoFar = 5;
        this.clientId = uuidv4();
        //console.log(this.clientId)
        this.outOf = 100;
        this.progressValue = 0;
        this.progressBufValue = 0;
        this.progressErrCount = 0;

        this.socket = io(API_WS_URL);

        this.socket.on('connect', () => {
          //console.log(this.socket.id);
        });

        this.socket.on('error', (error) => {
          if (this.socket) this.socket.disconnect();
          //this.progress = false
          //console.log('Socket error');
        });

        this.socket.on('msgToClient', (message) => {
          //console.log(message)
          if (
            message.clientId != this.clientId ||
            message.opId != 2 ||
            message.keyword != this.searchKey
          )
            return;
          //if(message.keyword != this.searchKey) return
          //this.fromDb = message.fromDB
          if (message.data == 'over') {
            this.progress = false;
            this.socket.disconnect();
            return;
          }
          this.gotSoFar = this.gotSoFar + 5;
          this.progressValue =
            this.progressValue + 500 / this.keywordDetails.appsRunningAds;
          this.progressBufValue =
            this.progressValue + 500 / this.keywordDetails.appsRunningAds;
          if (message.err) {
            this.progressErrCount = this.progressErrCount + 5;
            return;
          }
          const apps = JSON.parse(message.data);
          //console.log(apps)
          //this.progressText = 'Fetching more keywords... '+gotSoFar.toString()+'/'+this.appTPK.toString(),
          apps.map((x) => {
            //console.log(x)
            this.applicationInsights.push(x);
            
          });
          this.applicationInsights = Object.values(
              this.applicationInsights.reduce( (c, e) => {
                if (!c[e.appId]) c[e.appId] = e;
                return c;
              }, {})
            );
        });

        this.socket.on('disconnect', () => {
          //this.progress = false
          //console.log('Client Disconnected');
        });

        //this.socket.emit('msgToServer', 'Hello')

        this.loadingApps = true;
        this.applicationInsights = [];
        this.keywordDetails.popularity = 0;
        this.keywordDetails.noOfApps = 0;
        this.keywordDetails.chance = 0;
        this.keywordDetails.appsRunningAds = 0;

        this.$apollo
          .query({
            query: SEARCH_BY_KEYWORD,
            variables: {
              keyword: this.searchKey,
              opId: 2,
              clientId: this.clientId,
              startDate: this.startDate,
              endDate: this.endDate,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              currPage: Date.now(),
              itemsPerPage: 1000,
            },
          })
          .then((data) => {
            //console.log(data.data);
            if (
              data.data.searchByKeyword == null ||
              data.data.searchByKeyword == undefined ||
              !data.data.searchByKeyword.apps
            ) {
              this.loadingApps = false;
              this.noDataTextApps = 'No data to show';
              return;
            }
            updateCLF('kaiKeyword', this);
            this.applicationInsights = data.data.searchByKeyword.apps;
            this.applicationInsights = Object.values(
                    this.applicationInsights.reduce( (c, e) => {
                      if (!c[e.appId]) c[e.appId] = e;
                      return c;
                    }, {})
                  );
            this.keywordDetails.popularity =
              data.data.searchByKeyword.details.popularity;
            this.keywordDetails.noOfApps =
              data.data.searchByKeyword.details.noOfApps;
            this.keywordDetails.chance =
              data.data.searchByKeyword.details.chance;
            this.keywordDetails.appsRunningAds =
              data.data.searchByKeyword.details.appsRunningAds;
            this.loadingApps = false;
            this.outOf = this.keywordDetails.appsRunningAds;
            this.progressValue = 500 / this.keywordDetails.appsRunningAds;
            this.progressBufValue =
              this.progressValue + 500 / this.keywordDetails.appsRunningAds;
            this.fromDb = data.data.searchByKeyword.fromDB;
            if (!this.fromDb) {
              this.progress = true;
              const c = {
                done: false,
                preText: 'Fetching',
                text: ' Apps for keyword - ' + this.searchKey,
                path: 'keyword-auction-insights',
                query: 'key',
                icon: 'mdi-apps',
                disabled: true,
                progress: 500 / this.keywordDetails.appsRunningAds,
                total: this.keywordDetails.appsRunningAds,
                fetched: 5,
                clientId: this.clientId,
                appId: 'searching for a keyword',
                opId: 2,
                keyword: this.searchKey,
                location: this.selectedCountries.Code.toLowerCase(),
                language: this.language,
                device: this.device,
              };
              this.$root.$emit('addDataRequests', c);
            } else {
              this.progress = false;
              this.socket.disconnect();
            }
          })
          .catch((error) => {
            //console.log(error);
            this.progress = false;
            this.loadingApps = false;
          });
      }
    },
    getDataForKeywordOld(e) {
      // const c = new VueSocketIO({
      //     debug: true,
      //     connection: 'http://localhost:8092'
      // })
      // console.log(c)

      if (e.keyCode == 13) {
        if (this.searchKey.length < 3) {
          alert('Please enter atleast 3 characters');
          return;
        }
        this.fromDb = true;
        this.clientId = uuidv4();

        this.loadingApps = true;
        this.applicationInsights = [];
        this.keywordDetails.popularity = 0;
        this.keywordDetails.noOfApps = 0;
        this.keywordDetails.chance = 0;
        this.keywordDetails.appsRunningAds = 0;

        this.$apollo
          .query({
            query: SEARCH_BY_KEYWORD,
            variables: {
              keyword: this.searchKey,
              opId: 2,
              clientId: this.clientId,
              startDate: this.startDate,
              endDate: this.endDate,
              location: this.selectedCountries.Code.toLowerCase(),
              language: this.language,
              device: this.device,
              currPage: Date.now(),
              itemsPerPage: 1000,
            },
          })
          .then((data) => {
            //console.log(data.data);
            if (
              data.data.searchByKeyword == null ||
              data.data.searchByKeyword == undefined ||
              !data.data.searchByKeyword.apps
            ) {
              this.loadingApps = false;
              this.noDataTextApps = 'No data to show';
              return;
            }
            updateCLF('kaiKeyword', this);
            this.applicationInsights = data.data.searchByKeyword.apps;
            this.applicationInsights = Object.values(
                  this.applicationInsights.reduce( (c, e) => {
                    if (!c[e.appId]) c[e.appId] = e;
                    return c;
                  }, {})
                );
            this.keywordDetails.popularity =
              data.data.searchByKeyword.details.popularity;
            this.keywordDetails.noOfApps =
              data.data.searchByKeyword.details.noOfApps;
            this.keywordDetails.chance =
              data.data.searchByKeyword.details.chance;
            this.keywordDetails.appsRunningAds =
              data.data.searchByKeyword.details.appsRunningAds;
            this.loadingApps = false;
            this.fromDb = data.data.searchByKeyword.fromDB;
            if (!this.fromDb) {
              this.infoAlertFlag = true;
              const c = {
                done: false,
                preText: 'Fetching',
                text: ' Apps for keyword - ' + this.searchKey,
                path: 'keyword-auction-insights',
                query: 'key',
                icon: 'mdi-apps',
                disabled: true,
                progress: 0,
                total: this.keywordDetails.appsRunningAds,
                fetched: 0,
                clientId: this.clientId,
                appId: 'searching for a keyword',
                opId: 2,
                keyword: this.searchKey,
              };
              this.$root.$emit('addDataRequests', c);
            } else {
            }
          })
          .catch((error) => {
            // console.log(error);
            this.loadingApps = false;
          });
      }
    },
    getDataForKeywordPaginated(cp, perPage) {
      this.loadingApps = true;

      this.$apollo
        .query({
          query: SEARCH_BY_KEYWORD,
          variables: {
            keyword: this.searchKey,
            startDate: this.startDate,
            endDate: this.endDate,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            currPage: cp,
            itemsPerPage: perPage,
          },
        })
        .then((data) => {
          //console.log(data.data);
          if (
            data.data.searchByKeyword == null ||
            data.data.searchByKeyword == undefined
          ) {
            this.loadingApps = false;
            this.noDataTextApps = 'No data to show';
            return;
          }
          for (
            let index = 0;
            index < data.data.searchByKeyword.apps.length;
            index++
          ) {
            const element = data.data.searchByKeyword.apps[index];
            this.applicationInsights.push(element);
          }
          this.applicationInsights = Object.values(
                this.applicationInsights.reduce( (c, e) => {
                  if (!c[e.appId]) c[e.appId] = e;
                  return c;
                }, {})
              );
          this.loadingApps = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loadingApps = false;
        });
    },
    appClicked(appDet, appId) {
      //console.log('App is clicked -'+appDet.title,appId);
      this.closeApp();
      this.searchAppId = parseInt(appId);
      this.searchApp = appDet.title;
      this.appTitle = appDet.title;
      this.appIcon = appDet.icon;
      this.setKaiQuery(
        0,
        appId,
        appDet.icon,
        appDet.title,
        LSS.kaiQuery.keyword
      );
      this.searchAppFull = {
        icon:appDet.icon,
        id:parseInt(appId),
        title: appDet.title
      };
      this.progressSBK = false;
      LSS.drsba = parseInt(appId);
      this.tab = 0;
      // this.tabChanged(0)
      const fakeEvent = {
        keyCode: 13,
      };
      this.getKeysForApp();
      //this.allAppKeywords();
    },
    keyClicked(key) {
      this.searchKey = key.toLowerCase();
      this.progressSBA = false;
      LSS.drsbk = key;
      this.setKaiQuery(
        1,
        LSS.kaiQuery.appId,
        LSS.kaiQuery.icon,
        LSS.kaiQuery.title,
        key.toLowerCase()
      );
      this.tab = 1;
      // this.tabChanged(1)
      const fakeEvent = {
        keyCode: 13,
      };
      this.getDataForKeyword(fakeEvent);
    },
    plusKeyClicked(keyword) {
      //console.log(keyword);
      //this.addedKeywords.push(keyword);
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.showAddedKeyList = true;
      if (
        keyword &&
        !this.addedKeywords.find((kw) => kw.text === keyword) &&
        this.addedKeywords.length < KEYWORDS_PER_TRY
      ) {
        this.addedKeywords.push({
          text: keyword,
          matchType: 'EXACT',
          bidAmount: { currency: this.currency, amount: '0.01' },
        });
      }
    },
    deleteKeyword(keyword) {
      this.addedKeywords = this.addedKeywords.filter(
        (kw) => kw.text !== keyword
      );
    },
    tabChanged(tab) {
      switch (tab) {
        case 0:
          this.progressSBK = false;
          this.progressAIK = false;
          if (!LSS.drsba) break;
          this.searchAppId = LSS.drsba;
          this.appTitle = LSS.kaiQuery.title;
          this.appIcon = LSS.kaiQuery.icon;
          this.searchAppFull = null;
          this.setKaiQuery(
            0,
            LSS.kaiQuery.appId,
            LSS.kaiQuery.icon,
            LSS.kaiQuery.title,
            this.searchKey ? this.searchKey.toLowerCase() : null
          );
          if (this.sba) {
            this.getKeysForApp();
            this.sba = false;
          }
          this.progressSBA = true;
          setTimeout(() => {
            this.$root.$emit('get-dsrba', this.searchAppId);
          }, 500);
          break;
        case 1:
          this.progressSBA = false;
          this.progressAIK = false;
          if (!LSS.drsbk) break;
          this.searchKey = LSS.drsbk;
          this.setKaiQuery(
            1,
            LSS.kaiQuery.appId,
            LSS.kaiQuery.icon,
            LSS.kaiQuery.title,
            this.searchKey.toLowerCase()
          );
          if (this.sbk) {
            const fakeEvent = {
              keyCode: 13,
            };
            this.getDataForKeyword(fakeEvent);
            this.sbk = false;
          }
          this.progressSBK = true;
          setTimeout(() => {
            this.$root.$emit('get-dsrbk', this.searchKey.toLowerCase());
          }, 500);
          break;
        // case 2:
        //   this.progressSBA = false
        //   this.progressSBK = false
        //   this.compCloseApp()
        //   this.tab = 2;
        //   this.compappTitle = LSS.kaiQuery.title
        //   this.compappIcon = LSS.kaiQuery.icon
        //   const app = {
        //     id: parseInt(LSS.kaiQuery.appId),
        //     title: LSS.kaiQuery.title,
        //     icon: LSS.kaiQuery.icon,
        //   }
        //   this.searchCompeteId = app
        //   this.searchForCompetes(app);
        //   break;
        case 4:
          this.progressSBA = false;
          this.progressSBK = false;
        default:
          this.progressSBA = false;
          this.progressSBK = false;
          this.progressAIK = false;
          break;
      }
    },
    validateRange() {
      this.$refs.quickAddForm.validate();
    },
    applyFilter(mode) {
      if (mode == 1) {
        //console.log('clear filters-');
        this.popularityRange[0] = 0;
        this.popularityRange[1] = 100;
        this.popularityRange = [0, 100];
        this.organicRankRange[0] = 0;
        this.organicRankRange[1] = this.organicRankRangeMax;
        this.organicRankRange = [0, this.organicRankRangeMax];
        this.totalAppsRange[0] = 0;
        this.totalAppsRange[1] = this.totalAppsRangeMax;
        this.totalAppsRange = [0, this.totalAppsRangeMax];
        this.tags = [];
        this.filteredkeywordInsights = this.keywordInsights;
        this.filteredkeywordInsights = Object.values(
                this.filteredkeywordInsights.reduce( (c, e) => {
                  if (!c[e.name]) c[e.name] = e;
                  return c;
                }, {})
              );
        return;
      }
      if (mode == 2) {
        this.tags = [];
      }
      const popMin = this.popularityRange[0];
      const popMax = this.popularityRange[1];
      const orMin = this.organicRankRange[0];
      const orMax = this.organicRankRange[1];
      const taMin = this.totalAppsRange[0];
      const taMax = this.totalAppsRange[1];
      const keys = this.tags;
      this.filteredkeywordInsights = this.keywordInsights;
      this.filteredkeywordInsights = Object.values(
                this.filteredkeywordInsights.reduce( (c, e) => {
                  if (!c[e.name]) c[e.name] = e;
                  return c;
                }, {})
              );
      let tempkeywordInsights = [];
      for (
        let index = 0;
        index < this.filteredkeywordInsights.length;
        index++
      ) {
        const element = this.filteredkeywordInsights[index];
        if (keys.length != 0) {
          keys.forEach((key) => {
            if (
              parseInt(element.popularity) <= popMax &&
              parseInt(element.popularity) >= popMin &&
              parseInt(element.organicRank) <= orMax &&
              parseInt(element.organicRank) >= orMin &&
              parseInt(element.totalApps) <= taMax &&
              parseInt(element.totalApps) >= taMin &&
              element.name.includes(key)
            ) {
              tempkeywordInsights.push(element);
            }
          });
        } else {
          if (
            parseInt(element.popularity) <= popMax &&
            parseInt(element.popularity) >= popMin &&
            parseInt(element.organicRank) <= orMax &&
            parseInt(element.organicRank) >= orMin &&
            parseInt(element.totalApps) <= taMax &&
            parseInt(element.totalApps) >= taMin
          ) {
            tempkeywordInsights.push(element);
          }
        }
      }
      this.filteredkeywordInsights = tempkeywordInsights;
      this.filteredkeywordInsights = Object.values(
                this.filteredkeywordInsights.reduce( (c, e) => {
                  if (!c[e.name]) c[e.name] = e;
                  return c;
                }, {})
              );
    },
    closeApp() {
      //console.log('Close App is clicked -');
      this.progressSBA = false;
      LSS.drsba = 0;
      this.progress = false;
      // this.searchedApps = [...this.ownApps];
      this.searchAppId = '';
      this.searchAppFull = null;
      this.searchApp = '';
      this.appSubTitle = '';
      this.appTitle = '';
      this.appIcon = '';
      this.appTPK = '-';
      this.appAC = '-';
      this.showAppNameSection = false;
      this.loadingKeys = false;
      this.noDataTextKeys = 'No data to show';
      this.allKeywords = [];
      this.fewKeywords = [];
      this.keywordInsights = [];
      this.filteredkeywordInsights = [];
      this.allAppKeyRanks = [];
    },
    recomcloseApp() {
      //console.log('Close App is clicked -');
      this.recomSearchAppId = '';
      this.searchRecoms = '';
      this.searchApp = '';
      this.recomappSubTitle = '';
      this.recomappTitle = '';
      this.recomappIcon = '';
      this.recomappTPK = '-';
      this.recomappAC = '-';
      this.recomshowAppNameSection = false;
      this.recomloadingKeys = false;
      this.noDataTextKeys = 'No data to show';
      //this.allKeywords = [];
      //this.fewKeywords = [];
      this.recomkeywordInsights = [];
      this.recomfilteredkeywordInsights = [];
      //this.allAppKeyRanks = [];
    },
    compCloseApp() {
      this.compsLoading = false;
      LSS.drsc = 0;
      this.compappTitle = '';
      this.compappIcon = '';
      this.comps = [];
      this.searchCompeteId = null;
      this.searchedCompetes = [];
      this.searchedCompetes = [...this.ownApps];
      this.selectedAiModels = null;
      this.renderedInputs = [];
      this.aiInputsModel = {};
      this.aiInputs = [];
      this.allowedInputs = [];
      this.allowedInputsModel = {};
      this.appFromModelInput = null;
      this.filteredAIkeywordInsights = []
    },
    searchForApp(appName) {
      this.loading = true;
      this.$apollo
        .query({
          query: SEARCH_FOR_APPS,
          variables: {
            term: appName,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })
        .then((res) => {
          // console.log("appData",res.data);
          const data = res.data.searchForApps;
          this.searchedApps = [...this.ownApps];
          data.forEach((element) => {
            this.searchedApps.push(element);
          });
          //this.searchedApps = res.data.searchForApps
          if (this.searchAppId !== this.prevSearchAppId) {
            this.prevSearchAppId = this.searchAppId;
            if (this.searchAppId !== null && this.searchAppId !== '') {
              this.getKeysForApp();
            }
          }
          this.loading = false;
          this.disableAppSearch = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
          this.disableAppSearch = false;
        });
    },
    searchForAppOnClick() {
      this.disableAppSearch = true;
      this.loading = true;
      this.$apollo
        .query({
          query: SEARCH_FOR_APPS,
          variables: {
            term: this.searchTextForSBA,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })
        .then((res) => {
          const data = res.data.searchForApps;
          this.searchedApps = [...this.ownApps];
          data.forEach((element) => {
            this.searchedApps.push(element);
          });
          this.loading = false;
          this.disableAppSearch = false;
        })
        .catch((error) => {
          this.loading = false;
          this.disableAppSearch = false;
        });
    },
    async searchForCompetes(app) {
      // if(LSS.token && decodeToken(LSS.token).checkList){
      //   if(!(decodeToken(LSS.token).checkList.kaiComp)) await this.updateCL('kaiComp')
      // // }
      // console.log('888888')
      // console.log(this.compappIcon, this.compappTitle)
      // console.log(app.title, app.icon)
      LSS.drsc = app.id;
      this.compsLoading = true;
      this.comps = [];
      this.$apollo
        .query({
          query: SEARCH_FOR_COMPETES,
          variables: {
            appId: app.id,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            clientId: this.cacheSC ? 'test' : Date.now().toString(),
            appIcon: this.compappIcon,
            appName: this.compappTitle,
          },
        })
        .then((res) => {
          //console.log(res.data);
          updateCLF('kaiComp', this);
          const apps = res.data.getCompetitors;
          this.comps = apps.map((x) => {
            const t = {
              title: x.title,
              icon: x.icon,
              id: x.id,
              price: x.price,
              rating: x.rating?.toFixed(1) || 0,
              genres: aptweak_categories[x.genres[0]],
              version: x.version,
            };
            return t;
          });
          if (apps.length > 0) {
            this.compLoadingText = 'Loading Apps... please wait';
            this.compsLoading = false;
          }
          if (apps.length == 0) {
            this.compLoadingText =
              'Your request is being processed. Check status in Data Requests page';
          }
          // this.$refs.compsSelectRef.focus();
        })
        .catch((error) => {
          // console.log(error);
          this.compLoadingText = 'Loading Apps... please wait';
          LSS.drsc = 0;
          this.compsLoading = false;
        });
    },
    searchForRecoms(appId) {
      this.recomsLoading = true;
      this.recoms = [];
      this.$apollo
        .query({
          query: KEYWORD_RECOMENDS,
          variables: {
            appid: this.recomSearchAppId,
            country: this.selectedCountries.Code.toLowerCase(),
            term: '',
          },
        })
        .then((res) => {
          //console.log(res.data);
          const keys = res.data.KeywordRecomends.content
            ? res.data.KeywordRecomends.content
            : [];
          this.recoms = keys.map((x) => {
            const t = {
              keyword: x.keyword,
              popularity: x.volume,
            };
            return t;
          });
          this.recomsLoading = false;
        })
        .catch((error) => {
          this.recomsLoading = false;
        });
    },
    getCategoryTop() {
      LSS.drctd = +this.selCat;
      LSS.drctt = this.selCatType.id.toLowerCase();
      this.catTopLoading = true;
      this.catTop = [];
      this.$apollo
        .query({
          query: GET_CATEGORY_TOP,
          variables: {
            catId: +this.selCat,
            type: this.selCatType.id.toLowerCase() || 'free',
            now: this.cacheCT ? 0 : Date.now(),
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            clientId: 'test',
          },
        })
        .then((res) => {
          //console.log(res.data);
          updateCLF('kaiTop', this);
          const apps = res.data.getCategoryTop;
          this.catTop = apps.map((x) => {
            const t = {
              title: x.title,
              icon: x.icon,
              id: x.id,
              price: x.price,
              rating: x.rating?.toFixed(1) || 0,
              genres: aptweak_categories[x.genres[0]],
            };
            return t;
          });
          if (apps.length > 0) {
            this.catTopLoadingText = 'Loading Apps... please wait';
            this.catTopLoading = false;
          }
          if (apps.length == 0) {
            this.catTopLoadingText =
              'Your request is being processed. Check status in Data Requests page';
          }
        })
        .catch((error) => {
          //console.log(error);
          this.catTopLoadingText = 'Loading Apps... please wait';
          LSS.drctd = 0;
          this.catTopLoading = false;
        });
    },
    searchForAppCompetes(appName) {
      this.loadingCompetes = true;
      this.searchedCompetes = [];
      this.$apollo
        .query({
          query: SEARCH_FOR_APPS,
          variables: {
            term: appName,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.searchedCompetes = [...this.ownApps];
          const apps = res.data.searchForApps;
          const reduced = apps.map((x) => {
            const item = {
              title: x.title,
              id: x.id,
              icon: x.icon,
            };
            return item;
          });
          //console.log(reduced)
          //this.searchedCompetes = reduced
          reduced.map((y) => this.searchedCompetes.push(y));
          // if(this.searchCompeteId !== this.prevSearchCompeteId){
          //   this.prevSearchCompeteId = this.searchCompeteId;
          //   if(this.searchCompeteId !== null && this.searchCompeteId !== '') {
          //     //console.log(this.searchCompeteId)
          //     this.searchForCompetes(this.searchCompeteId);
          //   }
          // }
          this.loadingCompetes = false;
          //this.disableCompeteSearch = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loadingCompetes = false;
          //this.disableCompeteSearch = false;
        });
    },
    searchForAppCompetesOnClick() {
      // console.log(this.searchTextForCompete)
      this.loadingCompetes = true;
      this.searchedCompetes = [];
      this.$apollo
        .query({
          query: SEARCH_FOR_APPS,
          variables: {
            term: this.searchTextForCompete,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.searchedCompetes = [...this.ownApps];
          const apps = res.data.searchForApps;
          const reduced = apps.map((x) => {
            const item = {
              title: x.title,
              id: x.id,
              icon: x.icon,
            };
            return item;
          });
          //console.log(reduced)
          //this.searchedCompetes = reduced
          reduced.map((y) => this.searchedCompetes.push(y));
          // if(this.searchCompeteId !== this.prevSearchCompeteId){
          //   this.prevSearchCompeteId = this.searchCompeteId;
          //   if(this.searchCompeteId !== null && this.searchCompeteId !== '') {
          //     //console.log(this.searchCompeteId)
          //     this.searchForCompetes(this.searchCompeteId);
          //   }
          // }
          this.loadingCompetes = false;
          // this.searchCompete = this.searchTextForCompete
          //this.disableCompeteSearch = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loadingCompetes = false;
          //this.disableCompeteSearch = false;
        });
    },
    searchForAppRecoms(appName) {
      this.recomSearchLoading = true;
      this.searchedRecoms = [];
      this.$apollo
        .query({
          query: SEARCH_FOR_APPS,
          variables: {
            term: appName,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
          },
        })
        .then((res) => {
          this.searchedRecoms = [...this.ownApps];
          const apps = res.data.searchForApps;
          const reduced = apps.map((x) => {
            const item = {
              title: x.title,
              id: x.id,
            };
            return item;
          });
          reduced.map((y) => this.searchedRecoms.push(y));
          this.recomSearchLoading = false;
        })
        .catch((error) => {
          this.recomSearchLoading = false;
        });
    },
    pageChange(page) {
      //console.log('pageChange ',page);
    },
    runAds() {
      //console.log(this.addedKeywords);
      this.showAddedKeyList = false;
      LSS.keysFromKai = this.addedKeywords;
      this.selectAdgroupForKeywords = {
        from: 'kai',
        now: Date.now(),
        keysFromKai: this.addedKeywords,
      };
    },
    adgroupsSelectedForKeyword(appsWithCampaigns) {
      
      //console.log('adgroupsSelectedForKeyword',appsWithCampaigns);
      const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
      //this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
      this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
    },
    adgroupsKeysFromKai(data) {
      const selectedAiID = this.selectedAiModels;
 const featurePathTitle = this.aiModels.filter(item => selectedAiID.includes(item.id)).map(item => item.title).join(', ');
      const adgroups = this.getNested(data.camps, 'ADGROUP');
      const campaigns = this.getNested(data.camps, 'CAMPAIGN');
      this.adgroupMetadata = {
        selectedCampaigns:campaigns,
        selectedAdgroups: adgroups,
        KeysFromKai: data.keys,
        adamId: this.searchAppId,
        now: Date.now(),
        selectedProfile:featurePathTitle
      };
    },
    openSelectAdgroupForKeywords(event) {
      //console.log('openSelectAdgroupForKeywords',event);
      this.selectAdgroupForKeywords = {
        from: 'kai',
        now: Date.now(),
        keysFromKai: event,
      };
    },
    async addkeywordsToAdgroups(keywords) {
      //console.log('added in kai - keywords',keywords);
      const addKeywordsReq = {
        keywords: keywords,
        adgroups: this.adgroupMetadata.selectedAdgroups,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_KEYWORDS,
          variables: {
            addKeywords: addKeywordsReq,
          },
        });
        
        if (result.data.addKeywords.searchAdsErrors) {
          this.$notify.error(result.data.addKeywords.searchAdsErrors);
        } else {
          if(result.data.addKeywords.data) {
            this.$notify.warning(`Keywords added with some errors below <br>` + result.data.addKeywords.data);
          } else {
            this.$notify.success(this.$t('successMsg.keyAddedMsg'));
          }
          this.closeButton();
        }
      } catch {}
    },
    getNested(appsWithCampaigns, level) {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN'
                ? app.campaigns
                : [].concat(
                    ...app.campaigns.map((campaign) => campaign.adgroups)
                  )
            )
          );
    },
    countryChanged(item) {
      this.langs = this.selectedCountries.Languages;
      this.selectedLang = this.selectedCountries.Languages[0];
      this.language = this.selectedCountries.Languages[0].Code;
    },
    countryChangedAiTab(item) {
      this.langs = this.Countries.find(c => c.Name === this.aiInputsModel['country']).Languages;
      this.aiInputsModel['language'] = this.langs[0].Name;
    },
    langChanged() {
      this.language = this.selectedLang;
    },
    dateChanged() {
      const d = this.endDate + 'T00:00:00.000Z';
      const dt = new Date(d);
      this.startDate = new Date(dt.setDate(dt.getDate() - 60))
        .toISOString()
        .substr(0, 10);
      this.menu5 = false;
    },
    debounce: function (query) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.searchForApp(query);
      }, 500);
    },
    debounce2: function (query) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.searchForAppCompetes(query);
      }, 500);
    },
    debounce3: function (query) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.searchForAppRecoms(query);
      }, 500);
    },
    allAppKeywords() {
      this.$apollo
        .query({
          query: APP_KEYWORDS_FULL,
          variables: {
            appIds: this.searchAppId,
            location: this.selectedCountries.Code.toLowerCase(),
            language: this.language,
            device: this.device,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then((data) => {
          //console.log(data.data);
          this.allKeywords = data.data.getAppKeywordsFULL[0].keywords;
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    openQuickAddWithSource(source) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.sourceKeywords = source;
      this.quickAddDialog = true;
    },
    loadMore(){
        const kwsOfInterest = this.sourceKeywords
        .filter((keywordItem) => this.checkQuickAddCriteria(keywordItem))
        .slice(0, KEYWORDS_PER_TRY);
        
        if(kwsOfInterest.length>0){
          this.addMessage=true; 
         setTimeout(this.addAllKeyClicked, 400);

        }
    },
    addAllKeyClicked() {
      this.addedKeywords = [];
      this.quickAddDialog = false;
      this.showAddedKeyList = true;
      const kwsOfInterest = this.sourceKeywords
        .filter((keywordItem) => this.checkQuickAddCriteria(keywordItem))
        .slice(0, KEYWORDS_PER_TRY);
      for (const keyword of kwsOfInterest) {
        if (
          keyword &&
          !this.addedKeywords.find((kw) => kw.text === keyword.name) &&
          this.addedKeywords.length < KEYWORDS_PER_TRY
        ) {        
          this.addedKeywords.push({
            text: keyword.name,
            matchType: 'EXACT',
            bidAmount: { currency: this.currency, amount: '0.01' },
          });
        }
      } 
      this.addMessage=false;     
    },
    checkQuickAddCriteria(keywordItem) {
      for (const criteriaKey in this.quickAddModel) {
        let result;
        const compareValue = Number(keywordItem[criteriaKey]);
        const criteriaValues = this.quickAddModel[criteriaKey];

        if (
          criteriaValues.criteria === CriteriaOptions.IN_RANGE
            ? _.isNil(criteriaValues.from) || _.isNil(criteriaValues.to)
            : _.isNil(criteriaValues.value)
        )
          continue;

        switch (criteriaValues.criteria) {
          case CriteriaOptions.ALL: {
            result =
              compareValue >= Number(criteriaValues.from) &&
              compareValue <= Number(criteriaValues.to);
            break;
          }
          case CriteriaOptions.GREATER_THAN: {
            result = compareValue > Number(criteriaValues.value);
            break;
          }
          case CriteriaOptions.LESS_THAN: {
            result = compareValue < Number(criteriaValues.value);
            break;
          }
          case CriteriaOptions.EQUALS: {
            result = compareValue === Number(criteriaValues.value);
            break;
          }
          case CriteriaOptions.IN_RANGE: {
            result =
              compareValue >= Number(criteriaValues.from) &&
              compareValue <= Number(criteriaValues.to);
            break;
          }
        }

        if (!result) return false;
      }

      return true;
    },
    closeButton() {
      (this.showAddedKeyList = false), (this.addedKeywords = []);
    },
    updateProgress(message, task) {
      this.gotSoFarSBA = 0;
      this.outOfSBA = 0;
      const fetched = message?.fetched || task?.fetched || 0;
      // const total = message?.total || task?.total || 0;
      // const progress = message?.progress || task?.progress || 0;
      const total = (message?.CountPref)<=(message?.total)?(message?.CountPref || task?.total || 0):(message?.total || task?.total || 0);
      const progress =(message?.fetched/((message?.CountPref)<=(message?.total)?(message?.CountPref):(message?.total)))*100
      this.gotSoFarSBA = fetched;
      this.outOfSBA = total;
      this.progressValueSBA = progress;
      this.progressBufValueSBA = progress + 10;
      let keys = [];
      if (message) keys = JSON.parse(message?.data);
      keys.forEach((x) => {
        if (x.organicRank == 0) x.organicRank = 100001;
        if (x.popularity < 5) x.popularity = 5;
        this.keywordInsights.push(x);
      });
      if(this.keywordInsights && this.keywordInsights.length > 0){
        const orMxObj = this.keywordInsights.reduce((prev, current) => (prev.organicRank > current.organicRank) ? prev : current, { organicRank: 5 })
        this.organicRankRangeMax = orMxObj.organicRank + 5
        if(this.organicRankRangeMax < 10 ) this.organicRankRangeMax = 10

        const taMxObj = this.keywordInsights.reduce((prev, current) => (prev.totalApps > current.totalApps) ? prev : current, { totalApps: 10 })
        this.totalAppsRangeMax = taMxObj.totalApps + 50
        if(this.totalAppsRangeMax < 1000 ) this.totalAppsRangeMax = 1000
      }
      if(this.filteredkeywordInsights.length<this.CountPref){
         keys.forEach((x) => {
        if (x.organicRank == 0) x.organicRank = 100001;
        if (x.popularity < 5) x.popularity = 5;
        this.filteredkeywordInsights.push(x);
      });
      }
     this.filteredkeywordInsights = Object.values(
                this.filteredkeywordInsights.reduce( (c, e) => {
                  if (!c[e.name]) c[e.name] = e;
                  return c;
                }, {})
              );
      this.applyFilter(0)
      // this.filteredkeywordInsights.sort((a,b) => a.organicRank - b.organicRank)
      // this.sabSortDesc = true
      // this.sabSortDesc = false
      if (task.done || progress > 99) {
        this.progressSBA = false;
        LSS.drsba = 0;
        return;
      }
    },
    updateProgress1(task) {
      // console.log(task)
      this.gotSoFarSBA = 0;
      this.outOfSBA = 0;
      const fetched = task?.fetched || 0;
      const total = task?.total || 0;
      const progress = task?.progress || 0;
      this.gotSoFarSBA = fetched;
      this.outOfSBA = total;
      this.progressValueSBA = progress;
      this.progressBufValueSBA = progress + 10;
      if (task.done || progress > 99) {
        this.progressSBA = false;
        LSS.drsba = 0;
        return;
      }
    },
    updateProgressKey(message, task) {
      const fetched = message?.fetched || task?.fetched || 0;
      const total = message?.total || task?.total || 0;
      const progress = message?.progress || task?.progress || 0;
      this.gotSoFarSBK = 0;
      this.outOfSBK = 0;
      this.gotSoFarSBK = fetched;
      this.outOfSBK = total;
      this.progressValueSBK = progress;
      this.progressBufValueSBK = progress + 5;
      let apps = [];
      if (message) apps = JSON.parse(message.data);
      apps.forEach((x) => {
        if (x.organicRank == 0) x.organicRank = 100001;
        this.applicationInsights.push(x);
      });
      this.applicationInsights.sort((a, b) => a.organicRank - b.organicRank);
      this.applicationInsights = Object.values(
        this.applicationInsights.reduce( (c, e) => {
          if (!c[e.appId]) c[e.appId] = e;
          return c;
        }, {})
      );
      if (task.done || progress > 99) {
        this.progressSBK = false;
        LSS.drsbk = null;
        return;
      }
    },
    updateProgressKey1(task) {
      const fetched = task?.fetched || 0;
      const total = task?.total || 0;
      const progress = task?.progress || 0;
      this.gotSoFarSBK = 0;
      this.outOfSBK = 0;
      this.gotSoFarSBK = fetched;
      this.outOfSBK = total;
      this.progressValueSBK = progress;
      this.progressBufValueSBK = progress + 5;
      if (task.done || progress > 99) {
        this.progressSBK = false;
        LSS.drsbk = null;
        return;
      }
    },
    setKaiQuery(tab, id, icon, title, keyword) {
      let CountPref
      if (tab == 0)  CountPref = this.CountPref
      LSS.kaiQuery = {
        tab: tab,
        appId: id,
        title: title,
        location: this.selectedCountries.Code.toLowerCase(),
        language: this.language,
        device: this.device,
        icon: icon,
        keyword: keyword ? keyword.toLowerCase() : '',
        CountPref : CountPref?CountPref:''
      };
    },
    async callSuggestiveAi(){
      if(!this.aiModelForm || !this.selectedAiModels) {
        if(Array.isArray(this.$refs.aiModelFormRef)) {
          this.$refs.aiModelFormRef.forEach(refItem => refItem.validate())
        }
        return;
      }
      
      this.callingSuggestiveAi = true;
       if (!this.selectedAiModels) {
        return; // Exit the method if no value is selected
      }
      this.aikeysFetched = false
      this.progressTextAIK = 'Fetching keywords '
      this.progressAIK = false;
      this.fromDbAIK = true;
      this.gotSoFarAIK = 0;
      this.outOfAIK = 0;
      this.progressValueAIK = 0;
      this.progressBufValueAIK = 0;
      this.progressErrCountAIK = 0;
      this.loadingAIKeys = true;
      this.keywordAIInsights = [];
      this.filteredAIkeywordInsights = [];
      const clientId = Date.now().toString()
      // LSS.draik = clientId
      this.aiCLientId = clientId
      let result: any = {
        promptMacros: "",
        location: 'us',
        language: 'en',
        device: 'iphone',
        opId: 71,
        clientId: clientId,
        currPage: 0,
        itemsPerPage: 0,
        CountPref: 10,
        startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      }
       //selected Ai model
      const selectedAiID = this.selectedAiModels;
       const selectedAiModel = this.listSuggestiveModels.find((item) => item._id === selectedAiID);
       result.appModelName = selectedAiModel.modelName;
        result["promptMacros"] = { ...this.aiInputsModel };
        for(const key in this.allowedInputsModel) {
          result[key] = Number(this.allowedInputsModel[key])
        }
        const featurePathTitle = this.aiModels.filter(item => selectedAiID.includes(item.id)).map(item => item.title).join(', ');
        this.selectedProfile = featurePathTitle;
        result['featuredPath'] = featurePathTitle;
        result['appId'] = this.appFromModelInput.id;
        result['title'] = this.appFromModelInput.title;
        result['icon'] = this.appFromModelInput.icon;
        
        this.allowedInputs.forEach(key => {
          if(key === 'temperature') {
            result[key] = Number(this.allowedInputsModel[key])
          }
          
          if(key === 'language') {
            const country = this.Countries.find(c => c.Name === this.aiInputsModel['country']);

            const language = country.Languages.find(l => l.Name === this.aiInputsModel['language']);

            result['location'] = country.Code;
            result['language'] = language.Code;
          }
        })
        
        this.renderedInputs.forEach(input => {
          if(input.type === 'list') {
            result.promptMacros[input.promptMacro] = this.listItems[input.promptMacro].map(item => item.value).join(input.delimiter);
          }
        });
        
        result['appId'] = this.appFromModelInput.id;
        result['title'] = this.appFromModelInput.title;
        result['icon'] = this.appFromModelInput.icon;
        
        this.$apollo
          .query({
            query: GENERATE_AI_KEYWORDS_KAI,
            variables: {
              inputs: result
            },
          }).then(response =>  {
            this.callingSuggestiveAi = false;
            this.getAiCreditsForUser();
            if(!response.data || !response.data.suggestedKeywordListKAI || !response.data.suggestedKeywordListKAI.keywords || response.data.suggestedKeywordListKAI.keywords.length === 0) {
              this.aikeysFetched = false
              this.progressTextAIK = 'Fetching keywords '
              this.progressAIK = true;
              this.fromDbAIK = false;
              this.gotSoFarAIK = 0;
              this.outOfAIK = 0;
              this.progressValueAIK = 0;
              this.progressBufValueAIK = 0;
              this.progressErrCountAIK = 0;
              return
            }
            this.keywordAIInsights = response.data.suggestedKeywordListKAI.keywords
            this.filteredAIkeywordInsights = response.data.suggestedKeywordListKAI.keywords
            this.loadingAIKeys = false;
          }).catch(error => {
            //activity log in case error occurs
            
            // console.log(error);
            this.loadingAIKeys = false;
            this.progressAIK = false;
            this.$notify.error('An error occurred while trying to run suggestive AI.');
            this.callingSuggestiveAi = false;
          })
    },
      generateElements() {
      this.renderedInputs = [];
      const selectedAiID = this.selectedAiModels;

      // Find the object with _id
      const selectedAiModel = this.listSuggestiveModels.find((item) => item._id === selectedAiID);

      if (selectedAiModel) {
        const modelObj = JSON.parse(selectedAiModel.model);
        const publicVal = modelObj.public;
        this.aiInputs = publicVal.inputs ? publicVal.inputs : '';
        this.allowedInputs = (publicVal.allow && publicVal.allow.length && publicVal.allow.sort()) || [];
      }
      
      this.aiInputs.forEach(input => {
        if(input.type === 'list') {
          this.listItems[input.promptMacro] = [{ value: '' }];
        }
      })

      if (this.aiInputs.length > 0) {
        this.renderedInputs = this.aiInputs;
      }
      
  },
  addListItem(item) {
    let copy = _.cloneDeep(this.listItems);
    if(copy[item.promptMacro].length < item.limit) {
      copy[item.promptMacro] = [...copy[item.promptMacro], { value: '' }];
    } 
    
    this.listItems = copy;
  },
  removeListItem(item) {
    let copy = _.cloneDeep(this.listItems);
    const length = copy[item.promptMacro].length;
    if(length > 1) {
      copy[item.promptMacro] = copy[item.promptMacro].slice(0, length - 1);
    }
    
    this.listItems = copy;
  },
    repopulateOwnApps() {
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        this.ownApps = [];
        this.ownApps.push({
          header: this.$t('labels.yourApps'),
          divider: true,
        });
        LSS.ownApps.forEach((element) => {
          this.ownApps.push({
            title: element.appName,
            id: element.adamId,
            icon: element.appIcon,
          });
        });
        this.ownApps.push({
          header: this.$t('labels.suggestedApps'),
          divider: true,
        });
        this.searchedCompetes = [...this.ownApps];
        this.searchedRecoms = [...this.ownApps];
        this.searchedApps = [...this.ownApps];
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (LSS.kaiQuery) {
      this.tab = parseInt(LSS.kaiQuery.tab) || 0;
      const idx = ATCODES.findIndex((e) => e.Code === LSS.kaiQuery.location);
      this.selectedCountries = ATCODES[idx];
      this.langs = this.selectedCountries.Languages;
      let l = LSS.kaiQuery.language;
      if (LSS.kaiQuery.location === 'us' && LSS.kaiQuery.language === 'en') {
        l = 'us';
      }
      const idx2 = this.langs.findIndex((e) => e.Code === l);
      this.selectedLang = this.selectedCountries.Languages[idx2];
      this.language = LSS.kaiQuery.language;
      this.device = LSS.kaiQuery.device;
      if (parseInt(LSS.kaiQuery.tab) === 0) {
        this.tab = 0;
        this.closeApp();
        this.searchAppId = parseInt(LSS.kaiQuery.appId);
        this.appTitle = LSS.kaiQuery.title;
        this.appIcon = LSS.kaiQuery.icon;
        this.getKeysForApp();
      } else if (parseInt(LSS.kaiQuery.tab) === 1) {
        this.tab = 1;
        this.searchKey = LSS.kaiQuery.keyword
          ? LSS.kaiQuery.keyword.toLowerCase()
          : '';
        const fakeEvent = {
          keyCode: 13,
        };
        this.getDataForKeyword(fakeEvent);
      } else if (parseInt(LSS.kaiQuery.tab) === 2) {
        this.compCloseApp();
        this.tab = 2;
        this.compappTitle = LSS.kaiQuery.title;
        this.compappIcon = LSS.kaiQuery.icon;
        const app = {
          id: parseInt(LSS.kaiQuery.appId),
          title: LSS.kaiQuery.title,
          icon: LSS.kaiQuery.icon,
        };
        this.searchCompeteId = app;
        this.searchForCompetes(app);
      } else if (parseInt(LSS.kaiQuery.tab) === 3) {
        this.tab = 3;
        this.selCat = LSS.kaiQuery.catId
          ? LSS.kaiQuery.catId.toString()
          : '6014';
        this.selCatType = {
          title: LSS.kaiQuery.catType ? LSS.kaiQuery.catType.toUpperCase() : '',
          id: LSS.kaiQuery.catType,
        };
        this.getCategoryTop();
      } else {
      }
    }
    // LSS.kaiQuery = null;

    const tab = this.$route.query.tab ? this.$route.query.tab : '';
    if (tab == 'comp') {
      this.tab = 2;
    } else if (tab == 'recom') {
      this.tab = 4;
    } else if (tab == 'key') {
      this.tab = 1;
    } else if (tab == 'app') {
      this.tab = 0;
    } else if (tab == 'top') {
      this.tab = 3;
    }
  },
  watch: {
    quickAddDialog(value) {
    // resetting the dialog
      if (value) {
        if(this.totalAppsRange[0] == 0 &&
          this.organicRankRange[0] == 0 &&
          this.popularityRange[0] == 0 &&
          this.popularityRange[1] == 100
        ) {
          for (const criteriaKey in this.quickAddModel) {
            for (const filterKey in this.quickAddModel[criteriaKey]) {
              if(filterKey === 'value') this.quickAddModel[criteriaKey][filterKey] = null;
              if(filterKey === 'criteria') this.quickAddModel[criteriaKey][filterKey] = CriteriaOptions.ALL
              if(filterKey === 'from') {
                if(criteriaKey === 'organicRank') this.quickAddModel[criteriaKey][filterKey] = 0
                if(criteriaKey === 'popularity') this.quickAddModel[criteriaKey][filterKey] = 0
                if(criteriaKey === 'totalApps') this.quickAddModel[criteriaKey][filterKey] = 0
              }
              if(filterKey === 'to') {
                if(criteriaKey === 'organicRank') this.quickAddModel[criteriaKey][filterKey] = this.organicRankRangeMax
                if(criteriaKey === 'popularity') this.quickAddModel[criteriaKey][filterKey] = 100
                if(criteriaKey === 'totalApps') this.quickAddModel[criteriaKey][filterKey] = this.totalAppsRangeMax
              }
            }
          }
        }else{
          for (const criteriaKey in this.quickAddModel) {
            for (const filterKey in this.quickAddModel[criteriaKey]) {
              if(filterKey === 'value') this.quickAddModel[criteriaKey][filterKey] = null;
              if(filterKey === 'criteria') this.quickAddModel[criteriaKey][filterKey] = CriteriaOptions.IN_RANGE
              if(filterKey === 'from') {
                if(criteriaKey === 'organicRank') this.quickAddModel[criteriaKey][filterKey] = this.organicRankRange[0]
                if(criteriaKey === 'popularity') this.quickAddModel[criteriaKey][filterKey] = this.popularityRange[0]
                if(criteriaKey === 'totalApps') this.quickAddModel[criteriaKey][filterKey] = this.totalAppsRange[0]
              }
              if(filterKey === 'to') {
                if(criteriaKey === 'organicRank') this.quickAddModel[criteriaKey][filterKey] = this.organicRankRange[1]
                if(criteriaKey === 'popularity') this.quickAddModel[criteriaKey][filterKey] = this.popularityRange[1]
                if(criteriaKey === 'totalApps') this.quickAddModel[criteriaKey][filterKey] = this.totalAppsRange[1]
              }
            }
          }
        }
        
        this.isOrganicRange = true;
        this.isPopularityRange = true;
        this.isAppsRange = true;
      }
    },
    tagSearch(data) {
      //console.log(data);
    },
    search(query) {
      this.searchTextForSBA = query;
      // this.disableAppSearch = true;
      // query &&
      //   query !== this.searchAppId &&
      //   query.length > 2 &&
      //   this.debounce(query);
    },
    searchCompete(query, previous) {
      this.searchTextForCompete = query;
      // query &&
      //   query !== this.searchCompeteId &&
      //   query.length > 2 &&
      //   this.debounce2(query);
    },
    searchRecom(query) {
      query &&
        query !== this.searchCompeteId &&
        query.length > 2 &&
        this.debounce3(query);
    },
    options(val) {
      // if(this.page != this.pageCount || this.pagecount == 0){
      //   return;
      // }
      // this.loadingKeys = true;
      // this.currPage = val.page;
      // //to calculate length of last set
      // const totalSets = Math.ceil(this.appTPK/10);
      // let idx = 10;
      // if(this.currPage == totalSets){
      //     idx = (this.appTPK%10)-1;
      // }
      // this.getKeysForAppPagination(this.currPage, idx);
      //this.allAppKeywords()
    },
    options2(val) {
      // console.log('options2 ',val);
      // console.log("keyword Page now",this.page2)
      // console.log("keyword Page total",this.pageCount2)
      // if(this.page2 != this.pageCount2 || this.pagecount2 == 0){
      //   return;
      // }
      // this.loadingApps = true;
      // this.currPage2 = val.page;
      // //to calculate length of last set
      // const totalSets = Math.ceil(this.keywordDetails.appsRunningAds/10);
      // let idx = 10;
      // if(this.currPage2 == totalSets){
      //     idx = (this.keywordDetails.appsRunningAds%10)-1;
      // }
      // this.getDataForKeywordPaginated(this.currPage2, idx);
    },
  },
  beforeMount() {
    this.Countries = ATCODES;
    this.selectedCountries = ATCODES[88];
    this.langs = this.selectedCountries.Languages;
    this.selectedLang = this.selectedCountries.Languages[0];
    this.language = 'us';
    this.device = 'iphone';
    const d = this.endDate + 'T00:00:00.000Z';
    const dt = new Date(d);
    this.startDate = new Date(dt.setDate(dt.getDate() - 60))
      .toISOString()
      .substr(0, 10);
      if(this.$route.query.q){
      switch(LSS.kaiQuery.tab){
      case 0:{
        this.closeApp();
        this.searchAppId = parseInt(LSS.kaiQuery.appId);
        this.appTitle = LSS.kaiQuery.title;
        this.appIcon = LSS.kaiQuery.icon;
        if(this.searchAppId) this.getKeysForApp();
        break
      }
      case 1:{
        this.searchKey = LSS.kaiQuery.keyword
          ? LSS.kaiQuery.keyword.toLowerCase()
          : '';
        const fakeEvent = {
          keyCode: 13,
        };
        this.getDataForKeyword(fakeEvent);
        break
      }
      case 2:{
        this.compCloseApp();
        this.compappTitle = LSS.kaiQuery.title;
        this.compappIcon = LSS.kaiQuery.icon;
        const app = {
          id: parseInt(LSS.kaiQuery.appId),
          title: LSS.kaiQuery.title,
          icon: LSS.kaiQuery.icon,
        };
        this.searchCompeteId = app;
        this.searchForCompetes(app);
        break
      }
      case 3:{
        this.selCat = LSS.kaiQuery.catId
          ? LSS.kaiQuery.catId.toString()
          : '6014';
        this.selCatType = {
          title: LSS.kaiQuery.catType ? LSS.kaiQuery.catType.toUpperCase() : '',
          id: LSS.kaiQuery.catType,
        };
        this.getCategoryTop();
        break
      }
    } }
  },
  created() {
    this.sba = true;
    this.sbk = true;
   
  },
  mounted() {
    
    this.listModels();
    this.listModelsDropDown();
    this.getAiCreditsForUser();
    
    Vue.prototype.$sckt.on('kwgen:process', async (testRunEvent) => {
      
      if(!this.modelProcessing) return;
      
      this.modelData = {};
      
      const message = '[' + testRunEvent.message.timestamp + '] ' + testRunEvent.message.message;
      
      this.modelProgressText = message;
      
      if(testRunEvent.data) {
          const data = JSON.parse(testRunEvent.data);
          this.modelData = testRunEvent;
          this.outputJson = { keywords: data, log: testRunEvent.logMessages.map(m => m.timestamp + ' : ' + m.message) };
          this.modelProcessing = false;
          this.modelProgressText = '';
          
          this.listModels();
      }
      
      if(testRunEvent.stack) {
          this.outputJson = { error: 'An error occurred while trying to process the model', stack: testRunEvent.stack, log: testRunEvent.logMessages.map(m => m.timestamp + ' : ' + m.message) };
          this.modelData = { logMessages: testRunEvent.logMessages }
          this.modelProcessing = false;
          this.modelProgressText = '';
      }
    });
    
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });

    this.locale = localStorage.getItem('locale') || 'en';
    if (!LSS.kaiQuery) {
      this.setKaiQuery(0, 0, '', '', '');
    } else {
      this.tab = parseInt(LSS.kaiQuery.tab) || 0;
      const idx = ATCODES.findIndex((e) => e.Code === LSS.kaiQuery.location);
      this.selectedCountries = ATCODES[idx];
      this.langs = this.selectedCountries.Languages;
      let l = LSS.kaiQuery.language;
      if (LSS.kaiQuery.location === 'us' && LSS.kaiQuery.language === 'en') {
        l = 'us';
      }
      const idx2 = this.langs.findIndex((e) => e.Code === l);
      this.selectedLang = this.selectedCountries.Languages[idx2];
      this.language = LSS.kaiQuery.language;
      this.device = LSS.kaiQuery.device;
      if(parseInt(LSS.kaiQuery.tab)==0) this.CountPref = LSS.kaiQuery.CountPref
    }
    if (!LSS.drsc) LSS.drsc = 0;
    if (!LSS.drctd) LSS.drctd = 6014;
    if (!LSS.drctt) LSS.drctt = 'free';
    if (!LSS.drsba) LSS.drsba = 0;
    else {
      setTimeout(() => {
        this.$root.$emit('get-dsrba', this.searchAppId);
      }, 500);
    }
    if (!LSS.drsbk) LSS.drsbk = null;
    else {
      setTimeout(() => {
        this.$root.$emit('get-dsrbk', this.searchKey.toLowerCase());
      }, 500);
    }
    // LSS.kaiQuery = null;

    const tab = this.$route.query.tab ? this.$route.query.tab : '';
    if (tab == 'comp') {
      this.tab = 2;
    } else if (tab == 'recom') {
      this.tab = 4;
    } else if (tab == 'key') {
      this.tab = 1;
    } else if (tab == 'app') {
      this.tab = 0;
    } else if (tab == 'top') {
      this.tab = 3;
    }

    this.repopulateOwnApps();
    // this.getCategoryTop();
    this.categories = at_cats.filter((x) => x.id != 0);

    this.$root.$on('socket-msg-sba', (msg, task) => {
      this.updateProgress(msg, task);
    });
    this.$root.$on('socket-msg-sba-get', (msg, task) => {
      this.updateProgress1(task);
    });
    this.$root.$on('socket-msg-sbk', (msg, task) => {
      this.updateProgressKey(msg, task);
    });
    this.$root.$on('socket-msg-sbk-get', (msg, task) => {
      this.updateProgressKey1(task);
    });

    this.$root.$on('socket-msg-sba-meta', (msg) => {
      if (msg) {
        const meta = JSON.parse(msg.data);
        this.appTitle = meta.title;
        this.appIcon = meta.icon;
        this.appSubTitle = meta.subtitle;
      }
    });
    this.$root.$on('socket-msg-sba-count', (msg) => {
      if (msg && Number(msg.data) === 0) {
        this.progressSBA = false;
        LSS.drsba = 0;
      }
      if (msg && Number(msg.data) > 0) {
        this.showAppNameSection = true;
        this.appTPK = Number(msg.data);
        this.outOfSBA = Number(msg.data);
      }
    });

    this.$root.$on('socket-msg-sbk-stat', (msg) => {
      if (msg) {
        const stat = JSON.parse(msg.data);
        if (stat.error && stat.error == 400) {
          alert(`Invalid inputs for - ${msg.keyword}`);
          this.progressSBK = false;
          LSS.drsbk = null;
          return;
        }
        this.keywordDetails.popularity = stat.popularity;
        this.keywordDetails.noOfApps = stat.noOfApps;
        this.keywordDetails.chance = stat.chance;
        this.keywordDetails.appsRunningAds = stat.appsRunningAds;
        if (stat.noOfApps == 0) {
          alert(`No app is using this keyword - ${msg.keyword}`);
          this.progressSBK = false;
          LSS.drsbk = null;
        }
        if (stat.noApps && stat.appsRunningAds == 0) {
          alert(`No app is running any ads on this keyword - ${msg.keyword}`);
          this.progressSBK = false;
          LSS.drsbk = null;
        }
      }
    });

    this.$root.$on('socket-msg-sc', (msg) => {
      // console.log(msg)
      if (msg) {
        const apps = JSON.parse(msg.data);
        this.comps = apps.map((x) => {
          const t = {
            title: x.title,
            icon: x.icon,
            id: x.id,
            price: x.price,
            rating: x.rating?.toFixed(1) || 0,
            genres: aptweak_categories[x.genres[0]],
            version: x.version,
          };
          return t;
        });
        if (apps.length > 0) {
          this.compLoadingText = 'Loading Apps... please wait';
          this.compsLoading = false;
        }
        if (apps.length == 0) {
          this.compLoadingText =
            'Your request is being processed. Check status in Data Requests page';
        }
      }
    });

    this.$root.$on('socket-msg-ct', (msg) => {
      // console.log(msg)
      if (msg) {
        const apps = JSON.parse(msg.data);
        this.catTop = apps.map((x) => {
          const t = {
            title: x.title,
            icon: x.icon,
            id: x.id,
            price: x.price,
            rating: x.rating?.toFixed(1) || 0,
            genres: aptweak_categories[x.genres[0]],
          };
          return t;
        });
        if (apps.length > 0) {
          this.catTopLoadingText = 'Loading Apps... please wait';
          this.catTopLoading = false;
        }
        if (apps.length == 0) {
          this.catTopLoadingText =
            'Your request is being processed. Check status in Data Requests page';
        }
      }
    });

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
      this.repopulateOwnApps();
    });

    this.$root.$on('dr-cancel', (msg) => {
      if (msg.opId === 1) {
        this.progressSBA = false;
        LSS.drsba = 0;
      }
      if (msg.opId === 2) {
        this.progressSBK = false;
        LSS.drsbk = null;
      }
    });
    this.$root.$on('socket-msg-aik-list', (msg) => {
      // console.log(msg)
      if (msg) {
        if(msg.clientId !== this.aiCLientId) return
        const apps = JSON.parse(msg.data);              
        this.loadingAIKeys = false;
        if(!apps || apps.length === 0){
          this.progressTextAIK = 'No suggestions '
          this.noAppsSuggestionAiTab = true;
          this.progressAIK = false;
          return
        }
        this.filteredAIkeywordInsights = apps.map((x) => {
          const t = {
            name: x,
            organicRank: '-',
            totalApps: '-',
            popularity: '-',
          };
          return t;
        });
        this.keywordAIInsights = this.filteredAIkeywordInsights
        this.aikeysFetched = true
        this.progressTextAIK = 'Fetching keywords info '
        this.progressAIK = true;
        this.fromDbAIK = false;
        this.gotSoFarAIK = 0;
        this.outOfAIK = apps.length;
        this.progressValueAIK = 0;
        this.progressBufValueAIK = 10;
        this.progressErrCountAIK = 0;
      }
    });
    this.$root.$on('socket-msg-aik-set', (msg) => {
      // console.log(msg)
      if (msg) {
        if(msg.clientId !== this.aiCLientId) return
        const data = JSON.parse(msg.data);
        const apps = data.keys
        const tempKeys = this.filteredAIkeywordInsights
        tempKeys.map((k,i) => {
          apps.map((x) => {
            if(k.name === x.name){
              k.organicRank = x.organicRank
              k.totalApps = x.totalApps
              k.popularity = x.popularity
            }
          });
        })
        this.filteredAIkeywordInsights = tempKeys
        this.keywordAIInsights = this.filteredAIkeywordInsights
        if(data.comp || data.progress>98){
          this.progressAIK = false;
          this.aiCLientId = null
        }else{
          this.progressAIK = true;
        }
        this.progressTextAIK = 'Fetching keywords info '
        this.fromDbAIK = false;
        this.gotSoFarAIK = data.currentCount;
        this.outOfAIK = data.totalCount;
        this.progressValueAIK = data.progress;
        // this.progressBufValueAIK = 1;
        // this.progressErrCountAIK = 0;
      }
    });
    this.$root.$on('socket-msg-aik-nocredits', (msg) => {
      if (msg) {
        if(msg.clientId !== this.aiCLientId) return
        this.filteredAIkeywordInsights = []
        this.keywordAIInsights = []
        this.progressAIK = false;
        this.progressTextAIK = 'Fetching keywords  '
        this.loadingAIKeys = false;
        this.fromDbAIK = false;
        this.gotSoFarAIK = 0;
        this.outOfAIK = 0;
        this.aiCLientId = null
        this.progressValueAIK = 0;
        this.getAiCreditsForUser();
      }
    });
  },
});
