var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard added-keyword mb-3" },
    [
      _c(
        "v-app",
        [
          _c(
            "v-navigation-drawer",
            {
              staticClass: "v-fixed-right kai-add",
              attrs: {
                app: "",
                fixed: "",
                temporary: "",
                right: "",
                width: "450",
              },
              model: {
                value: _vm.showAddedKeyList,
                callback: function ($$v) {
                  _vm.showAddedKeyList = $$v
                },
                expression: "showAddedKeyList",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { outlined: "", height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "headline d-flex justify-space-between px-5 py-3 mb-0",
                    },
                    [
                      _c("h5", { staticClass: "text-h6" }, [
                        _vm._v(_vm._s(_vm.$t("kai.addedKeywords"))),
                      ]),
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeButton()
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pa-2 pt-0" }, [
                    _c(
                      "div",
                      { staticClass: "keword-lists pa-3 pt-0" },
                      [
                        _c(
                          "v-form",
                          {
                            model: {
                              value: _vm.keyForm,
                              callback: function ($$v) {
                                _vm.keyForm = $$v
                              },
                              expression: "keyForm",
                            },
                          },
                          _vm._l(_vm.addedKeywords, function (keyword) {
                            return _c(
                              "v-row",
                              { key: keyword.text, staticClass: "mt-4" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 pt-0 pl-0 truncate",
                                    attrs: { cols: "12", sm: "4" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          width: "32",
                                          depressed: "",
                                          fab: "",
                                          text: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteKeyword(
                                              keyword.text
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-delete-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" " + _vm._s(keyword.text) + " "),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 pt-3 word-nowrap",
                                    attrs: { cols: "12", sm: "4" },
                                  },
                                  [
                                    _c("v-select", {
                                      staticClass: "no-field-details",
                                      attrs: {
                                        items: _vm.matchTypes,
                                        label: _vm.$t("labels.matchType"),
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                      },
                                      model: {
                                        value: keyword.matchType,
                                        callback: function ($$v) {
                                          _vm.$set(keyword, "matchType", $$v)
                                        },
                                        expression: "keyword.matchType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 pt-3",
                                    attrs: { cols: "12", sm: "4" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "field" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "text",
                                            required: "",
                                            outlined: "",
                                            dense: "",
                                            "prepend-inner-icon":
                                              "mdi-currency-usd",
                                            rules: _vm.amountRules,
                                          },
                                          model: {
                                            value: keyword.bidAmount.amount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                keyword.bidAmount,
                                                "amount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "keyword.bidAmount.amount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-space-between pa-3" },
                    [
                      _c("div", { staticClass: "pagination pa-2" }, [
                        _c("span", { staticClass: "pagination-total " }, [
                          _vm._v(
                            _vm._s(_vm.$t("kai.total")) +
                              ": " +
                              _vm._s(_vm.addedKeywords.length)
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right-action-btn d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                width: "127",
                                color: "grey",
                                depressed: "",
                                outlined: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeButton()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("kai.cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                width: "127",
                                color: "primary",
                                depressed: "",
                                disabled:
                                  _vm.addedKeywords.length == 0 || !_vm.keyForm,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.runAds()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("kai.runAds")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("SelectAdGroup", {
            attrs: { settings: _vm.selectAdgroupForKeywords },
            on: {
              onAdgroupSelection: function ($event) {
                return _vm.adgroupsSelectedForKeyword($event)
              },
              keysFromKai: function ($event) {
                return _vm.adgroupsKeysFromKai($event)
              },
            },
          }),
          _c("AddKeysKai", {
            attrs: { adgroupDetails: _vm.adgroupMetadata },
            on: {
              onClickChange: function ($event) {
                return _vm.openSelectAdgroupForKeywords($event)
              },
              onSaveKeywords: function ($event) {
                return _vm.addkeywordsToAdgroups($event)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "inner-container v-ads-manage-style style-2" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "top-page-header with-right-two-input page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c(
                    "div",
                    {},
                    [
                      _vm.renderUpdateVar
                        ? _c(
                            "v-tabs",
                            {
                              staticClass: " ",
                              attrs: {
                                "show-arrows": "",
                                "background-color": "transparent",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.tabChanged(_vm.tab)
                                },
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "v-tab",
                                { key: item.tab },
                                [
                                  _vm._v(" " + _vm._s(item.tab) + " "),
                                  item.id == 6
                                    ? [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                size: "16",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-shield-crown-outline"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_vm._v(" Super admin access only ")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.showFilters && _vm.renderUpdateVar
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex    align-center flex-wrap flex-lg-nowrap w-100 mt-3",
                            },
                            [
                              _c("v-select", {
                                staticClass: "result-count mx-3",
                                staticStyle: { "max-width": "100px" },
                                attrs: {
                                  label: "Result Count",
                                  items: _vm.CountPrefItems,
                                  dense: "",
                                  outlined: "",
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.searchAppId &&
                                    _vm.searchAppId != 604044220
                                      ? _vm.getKeysForApp()
                                      : ""
                                  },
                                },
                                model: {
                                  value: _vm.CountPref,
                                  callback: function ($$v) {
                                    _vm.CountPref = $$v
                                  },
                                  expression: "CountPref",
                                },
                              }),
                              _c(
                                "v-menu",
                                {
                                  staticStyle: { "max-width": "350px" },
                                  attrs: {
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                    "min-width": "278",
                                    "max-width": "350",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mx-2 w-xsm-100 mb-2 br pa-2 br-4 font-14 ",
                                                    attrs: {
                                                      color:
                                                        "bluegrey lighten-4",
                                                      outlined: "",
                                                    },
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("kai.popularity")
                                                    ) +
                                                    " : " +
                                                    _vm._s(
                                                      _vm.popularityRange[0]
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.popularityRange[1]
                                                    )
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-down"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3360319153
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "body-2  pa-0 pt-5 justify-center",
                                            },
                                            [
                                              _vm._v(
                                                "Filter by keyword popularity"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "text-right body-2 px-0 pt-4",
                                              }),
                                              _c("v-range-slider", {
                                                attrs: {
                                                  "thumb-label": true,
                                                  step: "1",
                                                  min: "0",
                                                  max: "100",
                                                  "background-color": "light",
                                                  "track-color": "grey",
                                                  "track-fill-color": "primary",
                                                  "thumb-color": "primary",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.applyFilter(0)
                                                  },
                                                },
                                                model: {
                                                  value: _vm.popularityRange,
                                                  callback: function ($$v) {
                                                    _vm.popularityRange = $$v
                                                  },
                                                  expression: "popularityRange",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                    "min-width": "278",
                                    "max-width": "350",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mx-2 w-xsm-100 mb-2 br pa-2 br-4 font-14 ",
                                                    attrs: {
                                                      color:
                                                        "bluegrey lighten-4",
                                                      outlined: "",
                                                    },
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " Organic Rank : " +
                                                    _vm._s(
                                                      _vm.organicRankRange[0]
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.organicRankRange[1]
                                                    )
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-down"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2773766312
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "body-2  pa-0 pt-5 justify-center",
                                            },
                                            [_vm._v("Filter by organic rank")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "text-right body-2 px-0 pt-4",
                                              }),
                                              _c("v-range-slider", {
                                                attrs: {
                                                  "thumb-label": true,
                                                  step: "1",
                                                  min: "0",
                                                  max: _vm.organicRankRangeMax,
                                                  "background-color": "light",
                                                  "track-color": "grey",
                                                  "track-fill-color": "primary",
                                                  "thumb-color": "primary",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.applyFilter(0)
                                                  },
                                                },
                                                model: {
                                                  value: _vm.organicRankRange,
                                                  callback: function ($$v) {
                                                    _vm.organicRankRange = $$v
                                                  },
                                                  expression:
                                                    "organicRankRange",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                    "min-width": "278",
                                    "max-width": "350",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mx-2 w-xsm-100 mb-2 br pa-2 br-4 font-14 ",
                                                    attrs: {
                                                      color:
                                                        "bluegrey lighten-4",
                                                      outlined: "",
                                                    },
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " Total Apps : " +
                                                    _vm._s(
                                                      _vm.totalAppsRange[0]
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.totalAppsRange[1]
                                                    )
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-down"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1979490993
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "body-2  pa-0 pt-5 justify-center",
                                            },
                                            [_vm._v("Filter by total apps")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "pa-0" },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "text-right body-2 px-0 pt-4",
                                              }),
                                              _c("v-range-slider", {
                                                attrs: {
                                                  "thumb-label": true,
                                                  step: "1",
                                                  min: "0",
                                                  max: _vm.totalAppsRangeMax,
                                                  "background-color": "light",
                                                  "track-color": "grey",
                                                  "track-fill-color": "primary",
                                                  "thumb-color": "primary",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.applyFilter(0)
                                                  },
                                                },
                                                model: {
                                                  value: _vm.totalAppsRange,
                                                  callback: function ($$v) {
                                                    _vm.totalAppsRange = $$v
                                                  },
                                                  expression: "totalAppsRange",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  staticClass: "keywork-search ",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                    right: "",
                                    "max-width": "400",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "mx-2 w-xsm-100 mb-2 br pa-2 br-4 font-14 ",
                                                    attrs: {
                                                      color:
                                                        "bluegrey lighten-4",
                                                      outlined: "",
                                                    },
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("kai.keyword")
                                                    ) +
                                                    " "
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("mdi-chevron-down"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1330898708
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "d-flex align-baseline justify-center v-search-box",
                                        },
                                        [
                                          _c("v-combobox", {
                                            staticClass: "tag-input",
                                            attrs: {
                                              multiple: "",
                                              label: _vm.$t("labels.enterKey"),
                                              "append-icon": "",
                                              "small-chips": "",
                                              chip: "",
                                              "deletable-chips": "",
                                              "search-input": _vm.tagSearch,
                                            },
                                            on: {
                                              "update:searchInput": function (
                                                $event
                                              ) {
                                                _vm.tagSearch = $event
                                              },
                                              "update:search-input": function (
                                                $event
                                              ) {
                                                _vm.tagSearch = $event
                                              },
                                              change: _vm.updateTags,
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.updateTags.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.tags,
                                              callback: function ($$v) {
                                                _vm.tags = $$v
                                              },
                                              expression: "tags",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                depressed: "",
                                                dense: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyFilter(1)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("kai.clear")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !(
                                _vm.tags.length == 0 &&
                                _vm.totalAppsRange[0] == 0 &&
                                _vm.organicRankRange[0] == 0 &&
                                _vm.popularityRange[0] == 0 &&
                                _vm.popularityRange[1] == 100
                              )
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depressed: "",
                                        dense: "",
                                        text: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyFilter(1)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "waring--text",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-window-close")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("kai.clrAllFilter"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-container",
                { staticClass: "v-main-content", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg extra-padding",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c(
                          "v-tabs-items",
                          {
                            staticClass: "pt-0 mt-1",
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          _vm._l(_vm.items, function (item) {
                            return _c(
                              "v-tab-item",
                              { key: item.id },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col mt-2" },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "align-center" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "10",
                                                      md: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header kai-box-header d-flex  align-start pa-0 mt-2",
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "v-text-no-field__details max-275 mt-2",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.country"
                                                            ),
                                                            items:
                                                              _vm.Countries,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.countryChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedCountries,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedCountries = $$v
                                                            },
                                                            expression:
                                                              "selectedCountries",
                                                          },
                                                        }),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "v-text-no-field__details mx-3 max-275 mt-2",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.lang"
                                                            ),
                                                            items: _vm.langs,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.langChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedLang,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedLang = $$v
                                                            },
                                                            expression:
                                                              "selectedLang",
                                                          },
                                                        }),
                                                        _c("v-combobox", {
                                                          ref: "searchAppRef",
                                                          refInFor: true,
                                                          staticClass:
                                                            "medium max-275  mt-2",
                                                          attrs: {
                                                            loading:
                                                              _vm.loading,
                                                            items:
                                                              _vm.searchedApps,
                                                            "search-input":
                                                              _vm.search,
                                                            outlined: "",
                                                            "hide-selected": "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label: _vm.$t(
                                                              "overview.searchApp"
                                                            ),
                                                            disabled:
                                                              _vm.loading,
                                                          },
                                                          on: {
                                                            "update:searchInput": function (
                                                              $event
                                                            ) {
                                                              _vm.search = $event
                                                            },
                                                            "update:search-input": function (
                                                              $event
                                                            ) {
                                                              _vm.search = $event
                                                            },
                                                            keyup: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.searchForAppOnClick.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "item",
                                                                fn: function (
                                                                  data
                                                                ) {
                                                                  return [
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticClass:
                                                                            "rounded-lg",
                                                                          attrs: {
                                                                            color:
                                                                              "secondary",
                                                                            "lazy-src":
                                                                              data
                                                                                .item
                                                                                .icon,
                                                                            "max-width":
                                                                              "20",
                                                                            "max-height":
                                                                              "20",
                                                                            "min-width":
                                                                              "20",
                                                                            "min-height":
                                                                              "20",
                                                                            src:
                                                                              data
                                                                                .item
                                                                                .icon,
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            {
                                                                              staticClass:
                                                                                "caption--text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  data
                                                                                    .item
                                                                                    .title
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "no-data",
                                                                fn: function () {
                                                                  return [
                                                                    _vm.search &&
                                                                    !_vm.loading
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-content",
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Press "
                                                                                    ),
                                                                                    _c(
                                                                                      "kbd",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "enter"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      ' to search for "'
                                                                                    ),
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.search
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      '" '
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.searchAppFull,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.searchAppFull = $$v
                                                            },
                                                            expression:
                                                              "searchAppFull",
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center mt-2 mb-4 ml-4 kai-btn-wrap",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "w-xsm-100 mr-3",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.sbaChanged(
                                                                      _vm.searchAppFull
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-magnify "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Search "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.appTitle
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "w-xsm-100 mr-3",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.closeApp,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Clear "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            !_vm.showDownloadSBAReportProgress &&
                                                            _vm.filteredkeywordInsights &&
                                                            _vm
                                                              .filteredkeywordInsights
                                                              .length > 1
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      " d-flex align-center justify-end ",
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadSBAReport()
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-img",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer",
                                                                                          attrs: {
                                                                                            src: require("../assets/images/csv.svg"),
                                                                                          },
                                                                                        },
                                                                                        "v-img",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "sr.downloadReport"
                                                                                  )
                                                                                )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.showDownloadSBAReportProgress
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      " d-flex align-center justify-end ",
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-progress-circular",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "ma-0 mr-1 cursor-pointer",
                                                                            attrs: {
                                                                              size:
                                                                                "18",
                                                                              width:
                                                                                "2",
                                                                              indeterminate:
                                                                                "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          },
                                                                          "v-progress-circular",
                                                                          _vm.attrs,
                                                                          false
                                                                        ),
                                                                        _vm.on
                                                                      )
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      lg: "2",
                                                      md: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center total-summary-content flex-wrap justify-end",
                                                      },
                                                      [
                                                        _vm.showAppNameSection
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "keyword-detail-right pt-0 d-flex justify-lg-end justify-md-end justify-sm-end justify-center font-weight-medium text-h5",
                                                                attrs: {
                                                                  cols: "12",
                                                                  lg: "4",
                                                                  sm: "4",
                                                                  md: "4",
                                                                  color:
                                                                    "white",
                                                                  elevation:
                                                                    "0",
                                                                  width: "100%",
                                                                  "max-width":
                                                                    "33.333%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "top-sheet-col d-flex justify-center flex-column align-center pr-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "mb-0 pt-0 margin-auto",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.appTPK
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text body-2 white-space-nowrap",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.totalPaidKey"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pb-0 d-flex align-baseline kai-box-2",
                                                    attrs: {
                                                      cols: "12",
                                                      md: "12",
                                                      xl: "7",
                                                      lg: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header kai-box-header-2  d-flex align-start pl-0 pa-3 pb-0 ",
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "v-text-no-field__details max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.country"
                                                            ),
                                                            items:
                                                              _vm.Countries,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.countryChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedCountries,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedCountries = $$v
                                                            },
                                                            expression:
                                                              "selectedCountries",
                                                          },
                                                        }),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "v-text-no-field__details mx-3 max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.lang"
                                                            ),
                                                            items: _vm.langs,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.langChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedLang,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedLang = $$v
                                                            },
                                                            expression:
                                                              "selectedLang",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "medium max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            label: "Keyword",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.searchKey,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.searchKey =
                                                                typeof $$v ===
                                                                "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                            },
                                                            expression:
                                                              "searchKey",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center kai-btn-wrap",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "w-xsm-100 ml-3",
                                                            attrs: {
                                                              color: "primary",
                                                              dark: "",
                                                              elevation: "0",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getDataForKeyword(
                                                                  _vm.searchKey
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-magnify "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" Search "),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.searchKey
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "w-xsm-100 ml-3",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  dark: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clearKeywordSearch()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Clear "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pb-8",
                                                    attrs: {
                                                      cols: "12",
                                                      md: "12",
                                                      xl: "5",
                                                      lg: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-center align-center total-summary-content flex-wrap mt-0",
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right font-weight-medium text-h5 pt-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "3",
                                                              sm: "6",
                                                              md: "3",
                                                              color: "white",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-content-center flex-column align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .keywordDetails
                                                                            .popularity
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "ml-3",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-chart-bell-curve-cumulative"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltip-div d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text-2 subtitle-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.popularity"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                          "max-width":
                                                                            "300px",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2 greyxdark--text",
                                                                                          attrs: {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-help-circle-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "kai.tooltip.popularity"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right font-weight-medium text-h5 pt-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "3",
                                                              sm: "6",
                                                              md: "3",
                                                              color: "white",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-content-center flex-column align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .keywordDetails
                                                                            .noOfApps
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltip-div d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text-2 subtitle-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.numOfApps"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                          "max-width":
                                                                            "300px",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2 greyxdark--text number-app-tooltip",
                                                                                          attrs: {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-help-circle-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "kai.tooltip.totalApps"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right font-weight-medium text-h5 pt-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "3",
                                                              sm: "6",
                                                              md: "3",
                                                              color: "white",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-content-center flex-column align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .keywordDetails
                                                                            .chance
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltip-div d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text-2 subtitle-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.chance"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                          "max-width":
                                                                            "300px",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2 greyxdark--text",
                                                                                          attrs: {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-help-circle-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "kai.tooltip.chance"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "keyword-detail-right font-weight-medium text-h5 pt-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "3",
                                                              sm: "6",
                                                              md: "3",
                                                              color: "white",
                                                              elevation: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "top-sheet-col d-flex justify-content-center flex-column align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 d-flex headline font-weight-bold text-h5 black--text text-with-info",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .keywordDetails
                                                                            .appsRunningAds
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tooltip-div d-flex",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "grey--text-2 subtitle-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "kai.appsRunAds"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                          "max-width":
                                                                            "300px",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2 greyxdark--text",
                                                                                          attrs: {
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                        },
                                                                                        "v-icon",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-help-circle-outline"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "kai.tooltip.appsRunning"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 3
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "box-header kai-box-header-3 d-flex align-start pa-3 pb-0 pt-4",
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pb-0 d-flex align-start kai-box-3",
                                                    attrs: {
                                                      cols: "12",
                                                      xl: "11",
                                                      lg: "11",
                                                      sm: "10",
                                                      md: "10",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-start ",
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "v-text-no-field__details max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.country"
                                                            ),
                                                            items:
                                                              _vm.Countries,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.countryChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedCountries,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedCountries = $$v
                                                            },
                                                            expression:
                                                              "selectedCountries",
                                                          },
                                                        }),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "v-text-no-field__details mx-3 max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.lang"
                                                            ),
                                                            items: _vm.langs,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.langChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedLang,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedLang = $$v
                                                            },
                                                            expression:
                                                              "selectedLang",
                                                          },
                                                        }),
                                                        _c("v-combobox", {
                                                          ref:
                                                            "searchCompeteRef",
                                                          refInFor: true,
                                                          staticClass:
                                                            "medium max-275 ",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCompetes,
                                                            items:
                                                              _vm.searchedCompetes,
                                                            "search-input":
                                                              _vm.searchCompete,
                                                            "hide-selected": "",
                                                            outlined: "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label: "Search App",
                                                            disabled:
                                                              _vm.loadingCompetes ||
                                                              _vm.compsLoading,
                                                          },
                                                          on: {
                                                            "update:searchInput": function (
                                                              $event
                                                            ) {
                                                              _vm.searchCompete = $event
                                                            },
                                                            "update:search-input": function (
                                                              $event
                                                            ) {
                                                              _vm.searchCompete = $event
                                                            },
                                                            keyup: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return _vm.searchForAppCompetesOnClick.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "item",
                                                                fn: function (
                                                                  data
                                                                ) {
                                                                  return [
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticClass:
                                                                            "rounded-lg",
                                                                          attrs: {
                                                                            color:
                                                                              "secondary",
                                                                            "lazy-src":
                                                                              data
                                                                                .item
                                                                                .icon,
                                                                            "max-width":
                                                                              "20",
                                                                            "max-height":
                                                                              "20",
                                                                            "min-width":
                                                                              "20",
                                                                            "min-height":
                                                                              "20",
                                                                            src:
                                                                              data
                                                                                .item
                                                                                .icon,
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            {
                                                                              staticClass:
                                                                                "caption--text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  data
                                                                                    .item
                                                                                    .title
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "no-data",
                                                                fn: function () {
                                                                  return [
                                                                    _vm.searchCompete &&
                                                                    !_vm.loadingCompetes
                                                                      ? _c(
                                                                          "v-list-item",
                                                                          [
                                                                            _c(
                                                                              "v-list-item-content",
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Press "
                                                                                    ),
                                                                                    _c(
                                                                                      "kbd",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "enter"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      ' to search for "'
                                                                                    ),
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.searchCompete
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      '" '
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.searchCompeteId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.searchCompeteId = $$v
                                                            },
                                                            expression:
                                                              "searchCompeteId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center kai-btn-wrap",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "w-xsm-100 ml-3",
                                                            attrs: {
                                                              color: "primary",
                                                              dark: "",
                                                              elevation: "0",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.compChanged(
                                                                  _vm.searchCompeteId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-magnify "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" Search "),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.searchCompeteId &&
                                                        _vm.searchCompeteId[
                                                          "id"
                                                        ]
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "w-xsm-100 ml-3",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  dark: "",
                                                                  elevation:
                                                                    "0",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.compCloseApp()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Clear "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-end align-center grid-list-view",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color:
                                                            _vm.sugCompLayout ==
                                                            0
                                                              ? "primary"
                                                              : "grey",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.sugCompLayout = 0
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-format-list-bulleted-type"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-3",
                                                        attrs: {
                                                          color:
                                                            _vm.sugCompLayout ==
                                                            1
                                                              ? "primary"
                                                              : "grey",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.sugCompLayout = 1
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" mdi-grid")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.sugCompLayout == 1
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "px-4 mt-0" },
                                                  _vm._l(
                                                    _vm.comps,
                                                    function (item, index) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "4",
                                                            md: "6",
                                                            sm: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "mx-auto br-light br-12",
                                                              attrs: {
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.compAppClicked(
                                                                    item[
                                                                      "title"
                                                                    ],
                                                                    item["id"],
                                                                    item["icon"]
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                  attrs: {
                                                                    "three-line":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    {
                                                                      staticClass:
                                                                        "br-12 rounded-lg",
                                                                      attrs: {
                                                                        tile:
                                                                          "",
                                                                        size:
                                                                          "45",
                                                                        color:
                                                                          "",
                                                                        rounded:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticClass:
                                                                            "br-12",
                                                                          attrs: {
                                                                            "lazy-src":
                                                                              item.icon,
                                                                            src:
                                                                              item.icon,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-18 truncate",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.rating
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2  font-18",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-star"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Genres - " +
                                                                                  _vm._s(
                                                                                    item.genres
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Version - " +
                                                                                  _vm._s(
                                                                                    item.version
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "primary--text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.price
                                                                                    ? "Price - " +
                                                                                        item.price
                                                                                    : ""
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 4
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "d-flex align-baseline",
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "pb-0",
                                                    attrs: {
                                                      cols: "12",
                                                      md: "10",
                                                      sm: "10",
                                                      lg: "10",
                                                      xl: "10",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "box-header d-flex  align-start pb-0 pa-3",
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "v-text-no-field__details max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.country"
                                                            ),
                                                            items:
                                                              _vm.Countries,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                            disabled:
                                                              _vm.catTopLoading,
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.countryChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedCountries,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedCountries = $$v
                                                            },
                                                            expression:
                                                              "selectedCountries",
                                                          },
                                                        }),
                                                        _c("v-select", {
                                                          staticClass:
                                                            "v-text-no-field__details mx-3 max-275 ",
                                                          attrs: {
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "kai.lang"
                                                            ),
                                                            items: _vm.langs,
                                                            "item-text": "Name",
                                                            "item-value":
                                                              "Code",
                                                            disabled:
                                                              _vm.catTopLoading,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.langChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedLang,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedLang = $$v
                                                            },
                                                            expression:
                                                              "selectedLang",
                                                          },
                                                        }),
                                                        _c("v-autocomplete", {
                                                          ref: "searchCatRef",
                                                          refInFor: true,
                                                          staticClass:
                                                            "medium max-275 ",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCats,
                                                            items:
                                                              _vm.categories,
                                                            outlined: "",
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            label: _vm.$t(
                                                              "labels.selectCategoryMsg"
                                                            ),
                                                            disabled:
                                                              _vm.catTopLoading,
                                                          },
                                                          model: {
                                                            value: _vm.selCat,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selCat = $$v
                                                            },
                                                            expression:
                                                              "selCat",
                                                          },
                                                        }),
                                                        _c("v-select", {
                                                          ref: "searchCatRef",
                                                          refInFor: true,
                                                          staticClass:
                                                            "medium ml-3 max-275 ",
                                                          attrs: {
                                                            loading:
                                                              _vm.loadingCatTypes,
                                                            items: _vm.catTypes,
                                                            "item-text":
                                                              "title",
                                                            "item-value": "id",
                                                            outlined: "",
                                                            dense: "",
                                                            label: _vm.$t(
                                                              "labels.selectCategoryType"
                                                            ),
                                                            "return-object": "",
                                                            disabled:
                                                              _vm.catTopLoading,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selCatType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selCatType = $$v
                                                            },
                                                            expression:
                                                              "selCatType",
                                                          },
                                                        }),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "w-xsm-100 ml-3",
                                                            attrs: {
                                                              color: "primary",
                                                              dark: "",
                                                              elevation: "0",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getCategoryTop()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-magnify "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" Search "),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-end align-center",
                                                    attrs: {
                                                      cols: "12",
                                                      md: "2",
                                                      sm: "2",
                                                      lg: "2",
                                                      xl: "2",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color:
                                                            _vm.catTopLayout ==
                                                            0
                                                              ? "primary"
                                                              : "grey",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.catTopLayout = 0
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-format-list-bulleted-type"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-3",
                                                        attrs: {
                                                          color:
                                                            _vm.catTopLayout ==
                                                            1
                                                              ? "primary"
                                                              : "grey",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.catTopLayout = 1
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" mdi-grid")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.catTopLayout == 1
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "px-4 mt-0" },
                                                  _vm._l(
                                                    _vm.visibleTopApp,
                                                    function (item, index) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "4",
                                                            md: "6",
                                                            sm: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "mx-auto br-light br-12",
                                                              attrs: {
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.compAppClicked(
                                                                    item[
                                                                      "title"
                                                                    ],
                                                                    item["id"],
                                                                    item["icon"]
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                  attrs: {
                                                                    "three-line":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    {
                                                                      staticClass:
                                                                        "br-12 rounded-lg",
                                                                      attrs: {
                                                                        tile:
                                                                          "",
                                                                        size:
                                                                          "45",
                                                                        color:
                                                                          "",
                                                                        rounded:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticClass:
                                                                            "br-12",
                                                                          attrs: {
                                                                            "lazy-src":
                                                                              item.icon,
                                                                            src:
                                                                              item.icon,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-18 truncate",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Genres - " +
                                                                                  _vm._s(
                                                                                    item.genres
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.rating
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2  font-18",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-star"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "primary--text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.price
                                                                                    ? "Price - " +
                                                                                        item.price
                                                                                    : ""
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.catTopLayout == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-end w-100 mt-6",
                                                  },
                                                  [
                                                    _c("v-pagination", {
                                                      attrs: {
                                                        length: _vm.pageCount3,
                                                        color: "primary",
                                                      },
                                                      model: {
                                                        value: _vm.page3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.page3 = $$v
                                                        },
                                                        expression: "page3",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 6
                                      ? _c(
                                          "div",
                                          [
                                            _c("DecisionAlert", {
                                              attrs: {
                                                DecisionAlertIcon: 1,
                                                DecisionAlert:
                                                  _vm.decisionAlertFlag,
                                                DecisionAlertTitle: "",
                                                DecisionAlertMessage:
                                                  "You want to delete the model?",
                                                DecisionAlertData:
                                                  _vm.decisionAlertData,
                                              },
                                              on: {
                                                emitDecisionAlertCancelClicked: function (
                                                  $event
                                                ) {
                                                  _vm.decisionAlertFlag = false
                                                },
                                                emitDecisionAlertOkClicked: function (
                                                  $event
                                                ) {
                                                  return _vm.decisionOkAction(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "7" } },
                                                  [
                                                    _c("JsonEditor", {
                                                      attrs: {
                                                        inputJson:
                                                          _vm.inputJson,
                                                      },
                                                      on: {
                                                        json: function (
                                                          $event
                                                        ) {
                                                          return _vm.suggestiveAiJsonModel(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "my-4 mr-2",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.isModelValid ||
                                                                    _vm.modelProcessing,
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.processModel()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-play"
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " Process"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm.modelProcessing
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "my-4",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.stopProcessing()
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-stop"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Stop"
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "justify-end",
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "my-4",
                                                                attrs: {
                                                                  loading:
                                                                    _vm.savingModel,
                                                                  disabled:
                                                                    !_vm.modelName ||
                                                                    _vm.modelProcessing ||
                                                                    _vm.savingModel ||
                                                                    !_vm.isModelValid,
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveModel()
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-content-save-check-outline"
                                                                  ),
                                                                ]),
                                                                _vm._v(" Save"),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _vm.modelProcessing
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center justify-center flex-column",
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    size: "48",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "greybase--text ma-0 mt-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.modelProgressText
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    !_vm.modelProcessing &&
                                                    _vm.outputJson
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "json-viewer",
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "8",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 expand-btn",
                                                                          attrs: {
                                                                            label:
                                                                              "Expand All",
                                                                            "true-value": 100,
                                                                            "false-value": 1,
                                                                            color:
                                                                              "primary",
                                                                            "hide-details":
                                                                              "",
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.expand1,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.expand1 = $$v
                                                                            },
                                                                            expression:
                                                                              "expand1",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "justify-end",
                                                                      attrs: {
                                                                        cols:
                                                                          "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "my-4",
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            small:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.exportAiKeywords()
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Export CSV"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "json-viewer",
                                                                {
                                                                  key:
                                                                    _vm.expand1,
                                                                  attrs: {
                                                                    "expand-depth":
                                                                      _vm.expand1,
                                                                    value:
                                                                      _vm.outputJson,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-data-table", {
                                                      staticClass:
                                                        "elevation-0 mt-4 table-style-3",
                                                      attrs: {
                                                        headers: _vm.aimHeaders,
                                                        items: _vm.aimItems,
                                                        "items-per-page": 10,
                                                        "item-key": "_id",
                                                        loading: _vm.aimLoading,
                                                        "fixed-header": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item.sno",
                                                            fn: function (ref) {
                                                              var index =
                                                                ref.index
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      index + 1
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key:
                                                              "item.isPublic",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    class: {
                                                                      "greybase--text":
                                                                        _vm.getPublicName(
                                                                          item
                                                                        ) ===
                                                                        "(private)",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getPublicName(
                                                                          item
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key:
                                                              "item.updatedOn",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.localDate(
                                                                        item.updatedOn
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "item.view",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 font-22",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled: !(
                                                                                          item.logMessages &&
                                                                                          item
                                                                                            .logMessages
                                                                                            .length
                                                                                        ),
                                                                                        dark:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.viewModel(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-eye-outline "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "View keyword generator model"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "item.csv",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 font-22",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        disabled: !item.csv,
                                                                                        dark:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.downloadCsv(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-download-outline "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Download results as csv"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "item.restore",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 ",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        dark:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.restoreModel(
                                                                                            item
                                                                                          )
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-restart "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Restore model to the editor"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "item.delete",
                                                            fn: function (ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        dark:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteModelAsk(
                                                                                            item.modelName
                                                                                          )
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-delete-outline "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Delete model"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 7
                                      ? _c(
                                          "div",
                                          { staticClass: "search-col" },
                                          [
                                            _c("DecisionAlert", {
                                              attrs: {
                                                DecisionAlertIcon: 2,
                                                DecisionAlert:
                                                  _vm.noAppsSuggestionAiTab,
                                                DecisionAlertTitle: "",
                                                DecisionAlertMessage:
                                                  _vm.alertMessage,
                                                onlyMessage: true,
                                              },
                                              on: {
                                                emitDecisionAlertOkClicked: function (
                                                  $event
                                                ) {
                                                  _vm.noAppsSuggestionAiTab = false
                                                },
                                                emitDecisionAlertCancelClicked: function (
                                                  $event
                                                ) {
                                                  _vm.noAppsSuggestionAiTab = false
                                                },
                                              },
                                            }),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "box-header kai-box-header-3 d-flex align-start pa-3 pb-5 pt-4",
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pb-0 d-flex align-start kai-box-3",
                                                    attrs: {
                                                      cols: "12",
                                                      xl: "8",
                                                      lg: "8",
                                                      sm: "6",
                                                      md: "9",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "v-text-no-field__details mr-3 max-275 ",
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        label:
                                                          "AI Suggestions By",
                                                        items: _vm.aiModels,
                                                        "item-text": "title",
                                                        "item-value": "id",
                                                        rules:
                                                          _vm.campaignGroupRules,
                                                        required: "",
                                                        placeholder:
                                                          "AI Profile",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.generateElements,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedAiModels,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedAiModels = $$v
                                                        },
                                                        expression:
                                                          "selectedAiModels",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            !!_vm.remainingUsage ||
                                                            _vm.isKochavaAccount,
                                                          bottom: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          staticClass:
                                                                            "w-xsm-100 ml-3",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            elevation:
                                                                              "0",
                                                                            loading:
                                                                              _vm.callingSuggestiveAi,
                                                                            disabled: _vm.isKochavaAccount
                                                                              ? _vm.callingSuggestiveAi
                                                                              : _vm.callingSuggestiveAi ||
                                                                                !_vm.remainingUsage,
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.callSuggestiveAi()
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Suggest Keywords "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "You are currently out of AI Credits."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm.selectedAiModels
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "w-xsm-100 ml-3",
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              dark: "",
                                                              elevation: "0",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.compCloseApp()
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" Clear ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "pb-0 d-flex align-end justify-end ",
                                                    attrs: {
                                                      cols: "12",
                                                      xl: "4",
                                                      lg: "4",
                                                      sm: "6",
                                                      md: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center justify-end w-100 mr-5",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "label--text-1 greybase--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Credits Remaining: " +
                                                                _vm._s(
                                                                  _vm.remainingUsage
                                                                ) +
                                                                " / " +
                                                                _vm._s(
                                                                  _vm.usageLimit
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              " ( Refills on " +
                                                                _vm._s(
                                                                  _vm.refillsOn
                                                                ) +
                                                                " ) "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-form",
                                                      {
                                                        ref: "aiModelFormRef",
                                                        refInFor: true,
                                                        model: {
                                                          value:
                                                            _vm.aiModelForm,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.aiModelForm = $$v
                                                          },
                                                          expression:
                                                            "aiModelForm",
                                                        },
                                                      },
                                                      [
                                                        _vm.renderedInputs
                                                          .length > 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-wrap align-start mt-4",
                                                              },
                                                              [
                                                                _vm._l(
                                                                  _vm.renderedInputs,
                                                                  function (
                                                                    input,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index,
                                                                        staticClass:
                                                                          "d-flex align-center",
                                                                      },
                                                                      [
                                                                        input.type ===
                                                                        "select"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  " mr-3 max-275 min-200",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-select",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-text-no-field__details mr-3 max-275",
                                                                                    attrs: {
                                                                                      outlined:
                                                                                        "",
                                                                                      label:
                                                                                        input.name,
                                                                                      dense:
                                                                                        "",
                                                                                      items:
                                                                                        input.options,
                                                                                      rules:
                                                                                        _vm.campaignGroupRules,
                                                                                      required:
                                                                                        "",
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .aiInputsModel[
                                                                                          input
                                                                                            .promptMacro
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.aiInputsModel,
                                                                                          input.promptMacro,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiInputsModel[input.promptMacro]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        input.type ===
                                                                        "text"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  " mr-3 max-245 min-200",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-text-no-field__details mr-3 max-275",
                                                                                    attrs: {
                                                                                      label:
                                                                                        input.name,
                                                                                      maxlength:
                                                                                        input.maxlength,
                                                                                      minlength:
                                                                                        input.minlength,
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      rules:
                                                                                        _vm.campaignGroupRules,
                                                                                      required:
                                                                                        "",
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .aiInputsModel[
                                                                                          input
                                                                                            .promptMacro
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.aiInputsModel,
                                                                                          input.promptMacro,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiInputsModel[input.promptMacro]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        input.type ===
                                                                        "list"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  " mr-3 max-245 min-200",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-dialog",
                                                                                      {
                                                                                        attrs: {
                                                                                          width:
                                                                                            "400",
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function (
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                var attrs =
                                                                                                  ref.attrs
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            color:
                                                                                                              "primary",
                                                                                                            dark:
                                                                                                              "",
                                                                                                          },
                                                                                                        },
                                                                                                        "v-btn",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "mdi-plus"
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            input.name
                                                                                                          ) +
                                                                                                          " ( " +
                                                                                                          _vm._s(
                                                                                                            _vm
                                                                                                              .listItems[
                                                                                                              input
                                                                                                                .promptMacro
                                                                                                            ] &&
                                                                                                              _vm
                                                                                                                .listItems[
                                                                                                                input
                                                                                                                  .promptMacro
                                                                                                              ]
                                                                                                                .length
                                                                                                          ) +
                                                                                                          " / " +
                                                                                                          _vm._s(
                                                                                                            input.limit
                                                                                                          ) +
                                                                                                          " ) "
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .listItemDialog[
                                                                                              input
                                                                                                .promptMacro
                                                                                            ],
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.listItemDialog,
                                                                                              input.promptMacro,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "listItemDialog[input.promptMacro]",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-card",
                                                                                          [
                                                                                            _c(
                                                                                              "v-card-title",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "h5",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        input.name
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "white--text",
                                                                                                    on: {
                                                                                                      click: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        var _obj
                                                                                                        _vm.listItemDialog = Object.assign(
                                                                                                          {},
                                                                                                          _vm.listItemDialog,
                                                                                                          ((_obj = {}),
                                                                                                          (_obj[
                                                                                                            input.promptMacro
                                                                                                          ] = false),
                                                                                                          _obj)
                                                                                                        )
                                                                                                      },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-close"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " px-6 py-3",
                                                                                              },
                                                                                              [
                                                                                                _vm._l(
                                                                                                  _vm
                                                                                                    .listItems[
                                                                                                    input
                                                                                                      .promptMacro
                                                                                                  ],
                                                                                                  function (
                                                                                                    item,
                                                                                                    index
                                                                                                  ) {
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-text-field",
                                                                                                        {
                                                                                                          key:
                                                                                                            index +
                                                                                                            "-" +
                                                                                                            input.promptMacro,
                                                                                                          staticClass:
                                                                                                            "v-text-no-field__details mr-3 max-275",
                                                                                                          attrs: {
                                                                                                            maxlength:
                                                                                                              input.maxlength,
                                                                                                            minlength:
                                                                                                              input.minlength,
                                                                                                            outlined:
                                                                                                              "",
                                                                                                            dense:
                                                                                                              "",
                                                                                                            rules:
                                                                                                              _vm.alphanumericRule,
                                                                                                            required:
                                                                                                              "",
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              item.value,
                                                                                                            callback: function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                item,
                                                                                                                "value",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "item.value",
                                                                                                          },
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "mt-2",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          small:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          disabled:
                                                                                                            _vm
                                                                                                              .listItems[
                                                                                                              input
                                                                                                                .promptMacro
                                                                                                            ]
                                                                                                              .length <
                                                                                                            2,
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.removeListItem(
                                                                                                              input
                                                                                                            )
                                                                                                          },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " mdi-minus "
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mx-2",
                                                                                                        attrs: {
                                                                                                          small:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          disabled:
                                                                                                            _vm
                                                                                                              .listItems[
                                                                                                              input
                                                                                                                .promptMacro
                                                                                                            ]
                                                                                                              .length >=
                                                                                                            input.limit,
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.addListItem(
                                                                                                              input
                                                                                                            )
                                                                                                          },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " mdi-plus "
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ],
                                                                                              2
                                                                                            ),
                                                                                            _c(
                                                                                              "v-card-actions",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-spacer"
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      color:
                                                                                                        "primary",
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        var _obj
                                                                                                        _vm.listItemDialog = Object.assign(
                                                                                                          {},
                                                                                                          _vm.listItemDialog,
                                                                                                          ((_obj = {}),
                                                                                                          (_obj[
                                                                                                            input.promptMacro
                                                                                                          ] = false),
                                                                                                          _obj)
                                                                                                        )
                                                                                                      },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " Save "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        input.type ===
                                                                        "url"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  " mr-3 max-245 min-200",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        input.name
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-text-no-field__details mr-3 max-275",
                                                                                    attrs: {
                                                                                      pattern:
                                                                                        "https?://.+",
                                                                                      outlined:
                                                                                        "",
                                                                                      dense:
                                                                                        "",
                                                                                      required:
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      input: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.validateURL(
                                                                                          _vm
                                                                                            .aiInputsModel[
                                                                                            input
                                                                                              .promptMacro
                                                                                          ],
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .aiInputsModel[
                                                                                          input
                                                                                            .promptMacro
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.aiInputsModel,
                                                                                          input.promptMacro,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiInputsModel[input.promptMacro]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _vm
                                                                                  .urlValidationStatus[
                                                                                  index
                                                                                ]
                                                                                  ? _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "red--text",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .urlValidationStatus[
                                                                                              index
                                                                                            ]
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        input.type ===
                                                                        "app_name"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  " mr-3 max-245 min-200",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-combobox",
                                                                                  {
                                                                                    ref:
                                                                                      "searchCompeteRef",
                                                                                    refInFor: true,
                                                                                    staticClass:
                                                                                      "medium max-275 ",
                                                                                    attrs: {
                                                                                      label:
                                                                                        "App",
                                                                                      loading:
                                                                                        _vm.loadingCompetes,
                                                                                      items:
                                                                                        _vm.searchedCompetes,
                                                                                      "search-input":
                                                                                        _vm.searchCompete,
                                                                                      "hide-selected":
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      "item-text":
                                                                                        "title",
                                                                                      "item-value":
                                                                                        "title",
                                                                                      "return-object": false,
                                                                                      disabled:
                                                                                        _vm.loadingCompetes ||
                                                                                        _vm.compsLoading,
                                                                                      rules:
                                                                                        _vm.campaignGroupRules,
                                                                                      required:
                                                                                        "",
                                                                                    },
                                                                                    on: {
                                                                                      "update:searchInput": function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.searchCompete = $event
                                                                                      },
                                                                                      "update:search-input": function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.searchCompete = $event
                                                                                      },
                                                                                      change: function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.appselectionChanged(
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                      keyup: function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          !$event.type.indexOf(
                                                                                            "key"
                                                                                          ) &&
                                                                                          _vm._k(
                                                                                            $event.keyCode,
                                                                                            "enter",
                                                                                            13,
                                                                                            $event.key,
                                                                                            "Enter"
                                                                                          )
                                                                                        ) {
                                                                                          return null
                                                                                        }
                                                                                        return _vm.searchForAppCompetesOnClick.apply(
                                                                                          null,
                                                                                          arguments
                                                                                        )
                                                                                      },
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "item",
                                                                                          fn: function (
                                                                                            data
                                                                                          ) {
                                                                                            return [
                                                                                              [
                                                                                                _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "rounded-lg",
                                                                                                    attrs: {
                                                                                                      color:
                                                                                                        "secondary",
                                                                                                      "lazy-src":
                                                                                                        data
                                                                                                          .item
                                                                                                          .icon,
                                                                                                      "max-width":
                                                                                                        "20",
                                                                                                      "max-height":
                                                                                                        "20",
                                                                                                      "min-width":
                                                                                                        "20",
                                                                                                      "min-height":
                                                                                                        "20",
                                                                                                      src:
                                                                                                        data
                                                                                                          .item
                                                                                                          .icon,
                                                                                                    },
                                                                                                  }
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-list-item-content",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-2",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-list-item-title",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "caption--text",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            data
                                                                                                              .item
                                                                                                              .title
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ],
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          key:
                                                                                            "no-data",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _vm.searchCompete &&
                                                                                              !_vm.loadingCompetes
                                                                                                ? _c(
                                                                                                    "v-list-item",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-list-item-content",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "v-list-item-title",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                " Press "
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "kbd",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "enter"
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _vm._v(
                                                                                                                ' to search for "'
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "strong",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    _vm._s(
                                                                                                                      _vm.searchCompete
                                                                                                                    )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _vm._v(
                                                                                                                '" '
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .aiInputsModel[
                                                                                          input
                                                                                            .promptMacro
                                                                                        ],
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.aiInputsModel,
                                                                                          input.promptMacro,
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "aiInputsModel[input.promptMacro]",
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      " mr-3 d-flex align-start flex-row w-fit-content",
                                                                  },
                                                                  _vm._l(
                                                                    _vm.allowedInputs,
                                                                    function (
                                                                      input,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: index,
                                                                        },
                                                                        [
                                                                          input ===
                                                                          "language"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-row",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-autocomplete",
                                                                                    {
                                                                                      staticClass:
                                                                                        "v-text-no-field__details mr-3 max-275",
                                                                                      attrs: {
                                                                                        outlined:
                                                                                          "",
                                                                                        dense:
                                                                                          "",
                                                                                        label: _vm.$t(
                                                                                          "kai.country"
                                                                                        ),
                                                                                        items:
                                                                                          _vm.Countries,
                                                                                        rules:
                                                                                          _vm.campaignGroupRules,
                                                                                        "item-text":
                                                                                          "Name",
                                                                                        "item-value":
                                                                                          "Name",
                                                                                        required:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.countryChangedAiTab,
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .aiInputsModel[
                                                                                            "country"
                                                                                          ],
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.aiInputsModel,
                                                                                            "country",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "aiInputsModel['country']",
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-select",
                                                                                    {
                                                                                      staticClass:
                                                                                        "v-text-no-field__details mr-3 max-275",
                                                                                      attrs: {
                                                                                        outlined:
                                                                                          "",
                                                                                        dense:
                                                                                          "",
                                                                                        label: _vm.$t(
                                                                                          "kai.lang"
                                                                                        ),
                                                                                        items:
                                                                                          _vm.langs,
                                                                                        rules:
                                                                                          _vm.campaignGroupRules,
                                                                                        "item-text":
                                                                                          "Name",
                                                                                        "item-value":
                                                                                          "Name",
                                                                                        required:
                                                                                          "",
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .aiInputsModel[
                                                                                            "language"
                                                                                          ],
                                                                                        callback: function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.aiInputsModel,
                                                                                            "language",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "aiInputsModel['language']",
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          input ===
                                                                          "temperature"
                                                                            ? [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mb-0 greybase--text caption--text d-block",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Correlation "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex align-start w-100",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "primary--text whitespace-nowrap",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "High"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-slider",
                                                                                      {
                                                                                        staticClass:
                                                                                          "max-245 px-4 min-200",
                                                                                        attrs: {
                                                                                          step: 0.25,
                                                                                          "thumb-label": true,
                                                                                          min:
                                                                                            "0",
                                                                                          max:
                                                                                            "1",
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .allowedInputsModel[
                                                                                              input
                                                                                            ],
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.allowedInputsModel,
                                                                                              input,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "allowedInputsModel[input]",
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "label",
                                                                                      {
                                                                                        staticClass:
                                                                                          "primary--text whitespace-nowrap",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Low"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                        ],
                                                                        2
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.sugCompLayout == 1
                                              ? _c(
                                                  "v-row",
                                                  { staticClass: "px-4 mt-0" },
                                                  _vm._l(
                                                    _vm.comps,
                                                    function (item, index) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: index,
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "4",
                                                            md: "6",
                                                            sm: "12",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "mx-auto br-light br-12",
                                                              attrs: {
                                                                outlined: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.compAppClicked(
                                                                    item[
                                                                      "title"
                                                                    ],
                                                                    item["id"],
                                                                    item["icon"]
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center",
                                                                  attrs: {
                                                                    "three-line":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    {
                                                                      staticClass:
                                                                        "br-12 rounded-lg",
                                                                      attrs: {
                                                                        tile:
                                                                          "",
                                                                        size:
                                                                          "45",
                                                                        color:
                                                                          "",
                                                                        rounded:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          staticClass:
                                                                            "br-12",
                                                                          attrs: {
                                                                            "lazy-src":
                                                                              item.icon,
                                                                            src:
                                                                              item.icon,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "font-18 truncate",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.title
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex align-center",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.rating
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2  font-18",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-star"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Genres - " +
                                                                                  _vm._s(
                                                                                    item.genres
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Version - " +
                                                                                  _vm._s(
                                                                                    item.version
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "primary--text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.price
                                                                                    ? "Price - " +
                                                                                        item.price
                                                                                    : ""
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          { staticClass: "filter-col pb-5" },
                                          [
                                            _c(
                                              "v-item-group",
                                              [
                                                _vm.showAppNameSection
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "d-flex align-start justify-space-between",
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-lg-start justify-md-start justify-sm-start justify-center pt-0",
                                                            attrs: {
                                                              cols: "12",
                                                              lg: "4",
                                                              md: "4",
                                                              sm: "4",
                                                            },
                                                          },
                                                          [
                                                            item.id == 1 &&
                                                            _vm.showAppNameSection
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "w-xsm-100",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      dark: "",
                                                                      elevation:
                                                                        "0",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openQuickAddWithSource(
                                                                          _vm.keywordInsights
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus-circle-outline"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "kai.addAllKey"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("div"),
                                    item.id == 1
                                      ? _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 table-style-3",
                                          attrs: {
                                            search: _vm.searchKeys,
                                            headers: _vm.headers,
                                            items: _vm.filteredkeywordInsights,
                                            loading: _vm.loadingKeys,
                                            "loading-text":
                                              "Loading keywords - Just a moment",
                                            "no-data-text": _vm.$t(
                                              "kai.showDataText"
                                            ),
                                            page: _vm.page,
                                            options: _vm.options,
                                            "items-per-page": _vm.itemsPerPage,
                                            "hide-default-footer": "",
                                            "sort-by": _vm.sabSortBy,
                                            "sort-desc": _vm.sabSortDesc,
                                          },
                                          on: {
                                            "update:page": function ($event) {
                                              _vm.page = $event
                                            },
                                            "update:options": function (
                                              $event
                                            ) {
                                              _vm.options = $event
                                            },
                                            "page-count": function ($event) {
                                              _vm.pageCount = $event
                                            },
                                            "update:sortBy": function ($event) {
                                              _vm.sabSortBy = $event
                                            },
                                            "update:sort-by": function (
                                              $event
                                            ) {
                                              _vm.sabSortBy = $event
                                            },
                                            "update:sortDesc": function (
                                              $event
                                            ) {
                                              _vm.sabSortDesc = $event
                                            },
                                            "update:sort-desc": function (
                                              $event
                                            ) {
                                              _vm.sabSortDesc = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm._l(
                                                _vm.headers.slice(1),
                                                function (h, i) {
                                                  return {
                                                    key: "header." + h.value,
                                                    fn: function (ref) {
                                                      var header = ref.header
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                              {
                                                key: "item.plus",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-2 pa-0",
                                                        attrs: {
                                                          width: "22",
                                                          height: "18",
                                                          "min-width": "20",
                                                          text: "",
                                                          depressed: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.plusKeyClicked(
                                                              item["name"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-plus-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.organicRank",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    item["organicRank"] < 1 ||
                                                    item["organicRank"] > 100000
                                                      ? [_vm._v("-")]
                                                      : [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.organicRank
                                                            )
                                                          ),
                                                        ],
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.name",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "pa-4 pr-0 lighten-4",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.keyClicked(
                                                              item["name"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "headline body-1 cursor-pointer body-sm-1 pa-0  text-capitalize",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.id == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center pt-5 d-flex align-center justify-end",
                                          },
                                          [
                                            _c("v-pagination", {
                                              staticClass:
                                                "justify-center w-100",
                                              attrs: {
                                                length: _vm.pageCount,
                                                "total-visible": 10,
                                              },
                                              model: {
                                                value: _vm.page,
                                                callback: function ($$v) {
                                                  _vm.page = $$v
                                                },
                                                expression: "page",
                                              },
                                            }),
                                            _c("v-select", {
                                              staticClass:
                                                "v-text-no-field__details mx-2 w-xsm-100 justify-end",
                                              staticStyle: {
                                                "max-width": "100px",
                                              },
                                              attrs: {
                                                label: "Items Per Page",
                                                items: _vm.paginationItems,
                                                outlined: "",
                                                dense: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.options.itemsPerPage = parseInt(
                                                    $event,
                                                    10
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.itemsPerPage,
                                                callback: function ($$v) {
                                                  _vm.itemsPerPage = $$v
                                                },
                                                expression: "itemsPerPage",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 mt-4 table-style-3",
                                          attrs: {
                                            search: _vm.searchApps,
                                            headers: _vm.appheaders,
                                            items: _vm.applicationInsights,
                                            loading: _vm.loadingApps,
                                            "loading-text":
                                              "Loading Apps... Please wait",
                                            "no-data-text": _vm.$t(
                                              "kai.showDataText"
                                            ),
                                            page: _vm.page2,
                                            options: _vm.options2,
                                            "items-per-page": _vm.itemsPerPage2,
                                            "hide-default-footer": "",
                                            "sort-by": _vm.sortKeyBy,
                                            "sort-desc": _vm.sortKeyDesc,
                                            customSort: _vm.customSort,
                                          },
                                          on: {
                                            "update:page": function ($event) {
                                              _vm.page2 = $event
                                            },
                                            "update:options": function (
                                              $event
                                            ) {
                                              _vm.options2 = $event
                                            },
                                            "page-count": function ($event) {
                                              _vm.pageCount2 = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm._l(
                                                _vm.appheaders,
                                                function (h, i) {
                                                  return {
                                                    key: "header." + h.value,
                                                    fn: function (ref) {
                                                      var header = ref.header
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                              {
                                                key: "item.organicRank",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    item["organicRank"] < 1 ||
                                                    item["organicRank"] > 100000
                                                      ? [_vm._v("-")]
                                                      : [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.organicRank
                                                            )
                                                          ),
                                                        ],
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pa-4 pr-0 d-flex my-0 rounded-lg w-fit-content card cursor-pointer",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.appClicked(
                                                              item[
                                                                "appDetails"
                                                              ],
                                                              item["appId"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg image-sm",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.appDetails
                                                                .icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src:
                                                              item.appDetails
                                                                .icon,
                                                          },
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-4",
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 font-weight-bold pa-0  body-sm-1 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item
                                                                        .appDetails
                                                                        .title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2 body-sm-2 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .appDetails
                                                                      .title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.is",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item["is"]) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.id == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount2 },
                                              model: {
                                                value: _vm.page2,
                                                callback: function ($$v) {
                                                  _vm.page2 = $$v
                                                },
                                                expression: "page2",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 3 && _vm.sugCompLayout == 0
                                      ? _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 mt-4 table-style-3",
                                          attrs: {
                                            headers: _vm.compHeaders,
                                            items: _vm.comps,
                                            loading: _vm.compsLoading,
                                            "loading-text": _vm.compLoadingText,
                                            "no-data-text": _vm.$t(
                                              "kai.showDataText"
                                            ),
                                            "hide-default-footer": "",
                                            "sort-by": _vm.sortCompBy,
                                            "sort-desc": _vm.sortCompDesc,
                                            customSort: _vm.customSort,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm._l(
                                                _vm.compHeaders,
                                                function (h, i) {
                                                  return {
                                                    key: "header." + h.value,
                                                    fn: function (ref) {
                                                      var header = ref.header
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                              {
                                                key: "header.name",
                                                fn: function (ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          "max-width": "300px",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 greyxdark--text",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-help-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Data"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(header.text) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pa-4 pr-0 d-flex rounded-lg w-fit-content card cursor-pointer",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.compAppClicked(
                                                              item["title"],
                                                              item["id"],
                                                              item["icon"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg image-sm cursor-pointer",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src: item.icon,
                                                          },
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8 cursor-pointer",
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 cursor-pointer font-weight-bold pa-0  body-sm-1 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2 body-sm-2 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.id == 4 && _vm.catTopLayout == 0
                                      ? _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 mt-0 table-style-3",
                                          attrs: {
                                            search: _vm.searchTops,
                                            headers: _vm.catTopHeaders,
                                            items: _vm.catTop,
                                            loading: _vm.catTopLoading,
                                            "loading-text":
                                              _vm.catTopLoadingText,
                                            "no-data-text": _vm.$t(
                                              "kai.showDataText"
                                            ),
                                            "items-per-page": 100,
                                            page: _vm.page3,
                                            options: _vm.options3,
                                            "hide-default-footer": "",
                                            "sort-by": _vm.sortTopBy,
                                            "sort-desc": _vm.sortTopDesc,
                                            customSort: _vm.customSort,
                                          },
                                          on: {
                                            "update:page": function ($event) {
                                              _vm.page3 = $event
                                            },
                                            "update:options": function (
                                              $event
                                            ) {
                                              _vm.options3 = $event
                                            },
                                            "page-count": function ($event) {
                                              _vm.pageCount3 = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header.name",
                                                fn: function (ref) {
                                                  var header = ref.header
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          "max-width": "300px",
                                                          color: "primarydark",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "ml-2 greyxdark--text",
                                                                          attrs: {
                                                                            small:
                                                                              "",
                                                                          },
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-help-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Data"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(header.text) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.appDetails",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pa-4 pr-0 d-flex rounded-lg w-fit-content card cursor-pointer",
                                                        attrs: {
                                                          color:
                                                            "grey lighten-4",
                                                          elevation: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.compAppClicked(
                                                              item["title"],
                                                              item["id"],
                                                              item["icon"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-img", {
                                                          staticClass:
                                                            "rounded-lg image-sm cursor-pointer",
                                                          attrs: {
                                                            color: "secondary",
                                                            "lazy-src":
                                                              item.icon,
                                                            "max-width": "50",
                                                            "max-height": "50",
                                                            "min-width": "20",
                                                            "min-height": "20",
                                                            src: item.icon,
                                                          },
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "pa-0 pl-8 cursor-pointer",
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "headline body-1 font-weight-bold pa-0 body-sm-1 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.title
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "pa-0 ma-0 body-2 body-sm-2 whitespace-nowrap",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.id == 7 &&
                                    _vm.filteredAIkeywordInsights &&
                                    _vm.filteredAIkeywordInsights.length
                                      ? _c(
                                          "div",
                                          { staticClass: "filter-col pb-5" },
                                          [
                                            _c(
                                              "v-item-group",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "d-flex align-start justify-space-between",
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-lg-start justify-md-start justify-sm-start justify-center pt-0",
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "4",
                                                          md: "4",
                                                          sm: "4",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "w-xsm-100",
                                                            attrs: {
                                                              color: "primary",
                                                              dark: "",
                                                              elevation: "0",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openQuickAddWithSource(
                                                                  _vm.filteredAIkeywordInsights
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-plus-circle-outline"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "kai.addAllKey"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 4 && _vm.catTopLayout == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "text-center pt-2" },
                                          [
                                            _c("v-pagination", {
                                              attrs: { length: _vm.pageCount3 },
                                              model: {
                                                value: _vm.page3,
                                                callback: function ($$v) {
                                                  _vm.page3 = $$v
                                                },
                                                expression: "page3",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.id == 7
                                      ? _c("v-data-table", {
                                          staticClass:
                                            "elevation-0 table-style-3",
                                          attrs: {
                                            search: _vm.searchAIKeys,
                                            headers: _vm.headers,
                                            items:
                                              _vm.filteredAIkeywordInsights,
                                            loading: _vm.loadingAIKeys,
                                            "loading-text":
                                              "Loading AI keywords - Just a moment",
                                            "no-data-text": _vm.$t(
                                              "kai.showDataText"
                                            ),
                                            "items-per-page":
                                              _vm.itemsPerPageAI,
                                            "hide-default-footer": true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm._l(
                                                _vm.headers.slice(1),
                                                function (h, i) {
                                                  return {
                                                    key: "header." + h.value,
                                                    fn: function (ref) {
                                                      var header = ref.header
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              header.text
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  header.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                }
                                              ),
                                              {
                                                key: "item.plus",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-2 pa-0",
                                                        attrs: {
                                                          width: "22",
                                                          height: "18",
                                                          "min-width": "20",
                                                          text: "",
                                                          depressed: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.plusKeyClicked(
                                                              item["name"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "primary--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-plus-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.sno",
                                                fn: function (ref) {
                                                  var index = ref.index
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(index + 1) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.organicRank",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    item["organicRank"] < 1 ||
                                                    item["organicRank"] > 100000
                                                      ? [_vm._v("-")]
                                                      : [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.organicRank
                                                            )
                                                          ),
                                                        ],
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item.name",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "pa-4 pr-0 lighten-4",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.keyClicked(
                                                              item["name"]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "headline body-1 cursor-pointer body-sm-1 pa-0  text-capitalize",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-snackbar",
                  {
                    attrs: {
                      "max-width": "500",
                      timeout: -1,
                      value: _vm.progress,
                      bottom: true,
                      right: true,
                      rounded: "",
                      color: "primary",
                      elevation: "12",
                      vertical: true,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.progressText) +
                        " (" +
                        _vm._s(_vm.gotSoFar) +
                        " of " +
                        _vm._s(_vm.outOf) +
                        ") "
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "white", text: "" },
                        on: { click: _vm.closeProgress },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                    _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: {
                        color: "white",
                        "buffer-value": _vm.progressBufValue,
                        value: _vm.progressValue,
                        stream: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          [
            _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-dialog",
                  {
                    staticClass: "quick-add-criteria",
                    attrs: { persistent: "", "max-width": "600px" },
                    model: {
                      value: _vm.quickAddDialog,
                      callback: function ($$v) {
                        _vm.quickAddDialog = $$v
                      },
                      expression: "quickAddDialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-form",
                          {
                            ref: "quickAddForm",
                            model: {
                              value: _vm.quickAddForm,
                              callback: function ($$v) {
                                _vm.quickAddForm = $$v
                              },
                              expression: "quickAddForm",
                            },
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "titleWithClose d-flex justify-space-between mb-4",
                              },
                              [
                                _c("span", { staticClass: "text-title" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("kai.quickAddCriteria"))
                                  ),
                                ]),
                                _vm.addMessage
                                  ? _c(
                                      "v-dialog",
                                      {
                                        attrs: { width: "362" },
                                        model: {
                                          value: _vm.loadingAlertFlag,
                                          callback: function ($$v) {
                                            _vm.loadingAlertFlag = $$v
                                          },
                                          expression: "loadingAlertFlag",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: { padding: "7px 0px" },
                                            attrs: { color: "primary" },
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass: "cust",
                                                attrs: { color: "primary" },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      color: "white",
                                                    },
                                                  },
                                                  [_vm._v(" Adding Keywords ")]
                                                ),
                                                _c("v-progress-linear", {
                                                  staticClass: "mb-0",
                                                  attrs: {
                                                    indeterminate: "",
                                                    color: "white",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.quickAddDialog = false
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "pb-0" },
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-medium primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("kai.organicRank")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: { row: "" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.organicCriteriaSelected(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.quickAddModel
                                                      .organicRank.criteria,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quickAddModel
                                                        .organicRank,
                                                      "criteria",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quickAddModel.organicRank.criteria",
                                                },
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "All",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .ALL,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText2"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .LESS_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText1"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .GREATER_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.equalTo"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .EQUALS,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.between"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .IN_RANGE,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isOrganicRange === true &&
                                        _vm.quickAddModel.organicRank
                                          .criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "From",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.fromOrganicRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.from,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "from",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.from",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isOrganicRange === true &&
                                        _vm.quickAddModel.organicRank
                                          .criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "To",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.toOrganicRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.to,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "to",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.to",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isOrganicRange === false
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Value",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .organicRank.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .organicRank,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.organicRank.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mb-3" }),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-medium primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "keyHeaders.popularity"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: { row: "" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.popularityCriteriaSelected(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.quickAddModel.popularity
                                                      .criteria,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quickAddModel
                                                        .popularity,
                                                      "criteria",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quickAddModel.popularity.criteria",
                                                },
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "All",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .ALL,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText2"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .LESS_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText1"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .GREATER_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.equalTo"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .EQUALS,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.between"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .IN_RANGE,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isPopularityRange === true &&
                                        _vm.quickAddModel.popularity
                                          .criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "From",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules:
                                                      _vm.fromPopularityRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .popularity.from,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .popularity,
                                                        "from",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.popularity.from",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isPopularityRange === true &&
                                        _vm.quickAddModel.popularity
                                          .criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "To",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules:
                                                      _vm.toPopularityRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .popularity.to,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .popularity,
                                                        "to",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.popularity.to",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isPopularityRange === false
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Value",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .popularity.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .popularity,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.popularity.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mb-3" }),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-medium primary--text",
                                              },
                                              [_vm._v(" Total Apps ")]
                                            ),
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: { row: "" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.appsCriteriaSelected(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.quickAddModel.totalApps
                                                      .criteria,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.quickAddModel
                                                        .totalApps,
                                                      "criteria",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "quickAddModel.totalApps.criteria",
                                                },
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: "All",
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .ALL,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText2"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .LESS_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.allConOperationText1"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .GREATER_THAN,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.equalTo"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .EQUALS,
                                                  },
                                                }),
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createAR.between"
                                                    ),
                                                    value:
                                                      _vm.criteriaOptionsEnum
                                                        .IN_RANGE,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isAppsRange === true &&
                                        _vm.quickAddModel.totalApps.criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "From",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules:
                                                      _vm.fromTotalAppsRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .totalApps.from,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .totalApps,
                                                        "from",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.totalApps.from",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isAppsRange === true &&
                                        _vm.quickAddModel.totalApps.criteria !==
                                          _vm.criteriaOptionsEnum.ALL
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "6" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "To",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    rules: _vm.toTotalAppsRules,
                                                  },
                                                  on: {
                                                    change: _vm.validateRange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .totalApps.to,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .totalApps,
                                                        "to",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.totalApps.to",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isAppsRange === false
                                          ? _c(
                                              "v-col",
                                              {
                                                staticClass: "pt-0",
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Value",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.quickAddModel
                                                        .totalApps.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.quickAddModel
                                                          .totalApps,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "quickAddModel.totalApps.value",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "justify-end px-8 pt-6 pb-8" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "black", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.quickAddDialog = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("kai.cancel")) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", elevation: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadMore()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("addKeys.addKeywords")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-snackbar",
                  {
                    attrs: {
                      "max-width": "500",
                      timeout: -1,
                      value: _vm.progressSBA,
                      bottom: true,
                      right: true,
                      rounded: "",
                      color: "primary",
                      elevation: "12",
                      vertical: true,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.progressTextSBA) +
                        " " +
                        _vm._s(_vm.appTitle.substring(0, 20)) +
                        " (" +
                        _vm._s(_vm.gotSoFarSBA) +
                        " of " +
                        _vm._s(_vm.outOfSBA) +
                        ") "
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.progressSBA = false
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                    _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: {
                        color: "white",
                        "buffer-value": _vm.progressBufValueSBA,
                        value: _vm.progressValueSBA,
                        stream: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          [
            _vm.tab === 4
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-snackbar",
                      {
                        attrs: {
                          "max-width": "500",
                          timeout: -1,
                          value: _vm.progressAIK,
                          bottom: true,
                          right: true,
                          rounded: "",
                          color: "primary",
                          elevation: "12",
                          vertical: true,
                        },
                      },
                      [
                        _vm.aikeysFetched
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.progressTextAIK) +
                                  " (" +
                                  _vm._s(_vm.gotSoFarAIK) +
                                  " of " +
                                  _vm._s(_vm.outOfAIK) +
                                  ")"
                              ),
                            ]
                          : [_vm._v(" " + _vm._s(_vm.progressTextAIK))],
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "white", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.progressAIK = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                        _c("v-progress-linear", {
                          staticClass: "mb-0",
                          attrs: {
                            color: "white",
                            "buffer-value": _vm.progressBufValueAIK,
                            value: _vm.progressValueAIK,
                            stream: "",
                          },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-snackbar",
                  {
                    attrs: {
                      "max-width": "500",
                      timeout: -1,
                      value: _vm.progressSBK,
                      bottom: true,
                      right: true,
                      rounded: "",
                      color: "primary",
                      elevation: "12",
                      vertical: true,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.progressTextSBK) +
                        " " +
                        _vm._s(_vm.searchKey.substring(0, 20)) +
                        " (" +
                        _vm._s(_vm.gotSoFarSBK) +
                        " of " +
                        _vm._s(_vm.outOfSBK) +
                        ") "
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.progressSBK = false
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                    _c("v-progress-linear", {
                      staticClass: "mb-0",
                      attrs: {
                        color: "white",
                        "buffer-value": _vm.progressBufValueSBK,
                        value: _vm.progressValueSBK,
                        stream: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.loadingApps || _vm.loadingKeys || _vm.recomloadingKeys
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: "362" },
                      model: {
                        value: _vm.loadingAlertFlag,
                        callback: function ($$v) {
                          _vm.loadingAlertFlag = $$v
                        },
                        expression: "loadingAlertFlag",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticStyle: { padding: "7px 0px" },
                          attrs: { color: "primary" },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "cust",
                              attrs: { color: "primary" },
                            },
                            [
                              _c("label", { staticStyle: { color: "white" } }, [
                                _vm._v(" Fetching data. Please stand by"),
                              ]),
                              _c("v-progress-linear", {
                                staticClass: "mb-0",
                                attrs: {
                                  indeterminate: "",
                                  color: "white",
                                  stream: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("InfoAlert", {
            attrs: {
              InfoAlert: _vm.infoAlertFlag,
              InfoAlertTitle: _vm.infoAlertTitle,
              InfoAlertMessage: _vm.infoAlertMessage,
            },
            on: {
              emitInfoAlertOkClicked: function ($event) {
                _vm.infoAlertFlag = false
              },
            },
          }),
        ],
        2
      ),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }