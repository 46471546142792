var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200", scrollable: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "w-100" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-0",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addKeysKai.addKeywords"))),
              ]),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-3", staticStyle: { "max-height": "680px" } },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "v-stepper",
                      {
                        attrs: { elevation: "0" },
                        model: {
                          value: _vm.step,
                          callback: function ($$v) {
                            _vm.step = $$v
                          },
                          expression: "step",
                        },
                      },
                      [
                        _c(
                          "v-stepper-header",
                          { staticClass: "justify-center" },
                          [
                            _c(
                              "v-stepper-step",
                              { staticClass: "pa-2", attrs: { step: "1" } },
                              [
                                _c("span", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("addKeysKai.selectTarget"))
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-items",
                          [
                            _c(
                              "v-stepper-content",
                              { staticClass: "pa-0", attrs: { step: "1" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "my-2" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "12", sm: "6" },
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "font-weight-medium subtitle-2 primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addKeysKai.h4Camp"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.totalCampaigns) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeysKai.selected"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      depressed: "",
                                                      text: "",
                                                      color: "info",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.change()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeysKai.change"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "font-weight-medium subtitle-2 primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "addKeysKai.h4Adgroups"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.totalAdgroups) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeysKai.selected"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      depressed: "",
                                                      text: "",
                                                      color: "info",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.change()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeysKai.change"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "max-bid d-flex" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "d-flex mt-4 mr-2 font-weight-medium subtitle-2 primary--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addKeysKai.maxCPTBid"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  staticClass: "mt-2",
                                                  attrs: {
                                                    items: _vm.maxCptBidOptions,
                                                    label: "Select CPT Bid",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value: _vm.maxCptBid,
                                                    callback: function ($$v) {
                                                      _vm.maxCptBid = $$v
                                                    },
                                                    expression: "maxCptBid",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "6" } },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "form",
                                                attrs: {
                                                  role: "form",
                                                  "lazy-validation": "",
                                                },
                                                on: {
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.valid,
                                                  callback: function ($$v) {
                                                    _vm.valid = $$v
                                                  },
                                                  expression: "valid",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      outlined: "",
                                                      height: "100%",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex pa-2",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "addKeysKai.addedKeywords"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pagination pa-2 pb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "pagination-total primary--text float-right position-relative",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "addKeywords.total"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.keywords
                                                                    .length
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticClass:
                                                          "pa-2 rounded-0 border-x-0",
                                                        attrs: {
                                                          outlined: "",
                                                          height: "58",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "addkeword",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "rounded-0 rounded-l-lg no-field-details mb-0",
                                                                    attrs: {
                                                                      required:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.keywordInput,
                                                                      callback: function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.keywordInput = $$v
                                                                      },
                                                                      expression:
                                                                        "keywordInput",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "rounded-0 rounded-r-lg border-l-0 ml-3",
                                                                    attrs: {
                                                                      width:
                                                                        "36",
                                                                      height:
                                                                        "40",
                                                                      depressed:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addKeyword()
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "primary--text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "addKeysKai.add"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "keword-lists pa-2 pt-4",
                                                      },
                                                      _vm._l(
                                                        _vm.keywords,
                                                        function (keyword) {
                                                          return _c(
                                                            "v-row",
                                                            {
                                                              key: keyword.text,
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 ",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "icon-hover primary--text my-0 ",
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteKeyword(
                                                                            keyword.text
                                                                          )
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        keyword.text
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 pt-3",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "no-field-details",
                                                                      attrs: {
                                                                        items:
                                                                          _vm.matchTypes,
                                                                        label:
                                                                          "Match Type",
                                                                        required:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        dense:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          keyword.matchType,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            keyword,
                                                                            "matchType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "keyword.matchType",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0 pt-3",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "field",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            type:
                                                                              "text",
                                                                            required:
                                                                              "",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            "prepend-inner-icon":
                                                                              "mdi-currency-usd",
                                                                            rules:
                                                                              _vm.amountRules,
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              keyword
                                                                                .bidAmount
                                                                                .amount,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                keyword.bidAmount,
                                                                                "amount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "keyword.bidAmount.amount",
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeysKai.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "primary",
                    depressed: "",
                    disabled: !_vm.valid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeysKai.apply")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "300", persistent: "" },
              model: {
                value: _vm.msgModel,
                callback: function ($$v) {
                  _vm.msgModel = $$v
                },
                expression: "msgModel",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline grey lighten-2" },
                    [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                  ),
                  _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.msgModel = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("addKeysKai.ok")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }