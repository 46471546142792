











import * as _ from 'lodash';
import Vue from 'vue';

import { EditorState, Text } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { defaultKeymap, indentWithTab } from "@codemirror/commands";

import { json } from '@codemirror/lang-json';

import { basicSetup } from "codemirror";
import { linter, lintGutter,Diagnostic } from '@codemirror/lint';

const jsonMap = require('json-source-map');

export default Vue.extend({
  name: 'JsonEditor',
  props: {
    inputJson: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      invalid: false,
      startState: {} as EditorState,
      view: {} as EditorView,
      keys:{
        "promptMacros": true,
        "properties":{
            "name":true,
            "modelVersion":true
        },
        "prompts": 
            {
                "prompt": true,
                "conditions": {
                  "writeKeywordsToTier":true,
                  "nextForEach": true
                }
            },
        "regexMacros": true,
        "public": {
          "enabled": true,
          "displayName":true,
          "inputs":{"promptMacro":true,"name":true,"type":true,"options":true, "delimiter": true, "limit": true},
          "allow":{
            "temperature":true,
            "language": true
          }
        }
      },
    }
  },
  
  watch: {
    inputJson(value) {
      if (value) {
        this.view.dispatch({
          changes: { from: 0, to: this.view.state.doc.length, insert: value }
        })
      }
    }
  },

  methods: {
    getErrorPosition(error: SyntaxError, doc: Text): number {
      let m
      if (m = error.message.match(/at position (\d+)/))
        return Math.min(+m[1], doc.length)
      if (m = error.message.match(/at line (\d+) column (\d+)/))
        return Math.min(doc.line(+m[1]).from + (+m[2]) - 1, doc.length)
      return 0
    }
  },

  mounted() {

    const jsonLang = json();

    let timeout;
    
    const fixedHeightEditor = EditorView.theme({
        "&": {height: "320px"},
        ".cm-scroller": {overflow: "auto"}
    })

    const inputListener = EditorState.transactionExtender.of(() => {
      if (timeout) return;

      timeout = setTimeout(() => {
        this.$emit('json', { code: this.view.state.sliceDoc(), valid: !this.invalid });
        timeout = null;
      }, 500);

      return null;
    });
    
    const jsonLinter = linter((view) => {
      try {
        const jsonObj = JSON.parse(view.state.doc.toString())
        const jsonMapObj= jsonMap.parse(view.state.doc.toString())
        if(jsonObj.public?.enabled){
            const diagnostics: Diagnostic[] = [];
            
            
            for(const el in jsonMapObj.data){
              if (jsonMapObj.data.hasOwnProperty(el)) {
                //level 1 keys check
                if(!Object.keys(this.keys).includes(el)){
                  diagnostics.push({
                      from: jsonMapObj.pointers['/'+el].key.pos,
                      to: jsonMapObj.pointers['/'+el].keyEnd.pos,
                      source: 'ai-model-validator',
                      severity: "error",
                      message: "Invalid key"
                  }) }

                if(el === 'public'){
                  // looping through public{}
                  let inputsFlag = false
                  if(typeof(jsonMapObj.data[el])!== 'object' || Array.isArray(jsonMapObj.data[el])){
                    diagnostics.push({
                              from: jsonMapObj.pointers['/'+el].value.pos,
                              to: jsonMapObj.pointers['/'+el].valueEnd.pos,
                              source: 'ai-model-validator',
                              severity: "error",
                              message: "Invalid type, object expected"
                    }) 
                  }
                  else{
                    for(const pub in jsonMapObj.data[el]){
                    //checking for level 1 keys inside public{}
                    if(!Object.keys(this.keys.public).includes(pub)){
                      diagnostics.push({
                      from: jsonMapObj.pointers['/'+el +'/'+pub].key.pos,
                      to: jsonMapObj.pointers['/'+el +'/'+pub].keyEnd.pos,
                      source: 'ai-model-validator',
                      severity: "error",
                      message: "Invalid key"
                      }) 
                    }
                    if(pub==='enabled'){
                      //if public.enabled is boolean
                      if(typeof(jsonMapObj.data[el][pub])!='boolean'){
                        diagnostics.push({
                          from: jsonMapObj.pointers['/'+el +'/'+pub].value.pos,
                          to: jsonMapObj.pointers['/'+el +'/'+pub].valueEnd.pos,
                          source: 'ai-model-validator',
                          severity: "error",
                          message: "Expected boolean"
                        }) 
                      }
                    }
                    if(pub==='allow'){
                      //checking if public.allow is array
                      if(Array.isArray(jsonMapObj.data[el][pub])){
                        //looping through public.allow[]
                        for(const [index,allow] of jsonMapObj.data[el][pub].entries()){
                          if(!Object.keys(this.keys.public.allow).includes(allow)){
                            diagnostics.push({
                              from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index].value.pos,
                              to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index].valueEnd.pos,
                              source: 'ai-model-validator',
                              severity: "error",
                              message: "Invalid value, " + Object.keys(this.keys.public.allow).join(' or ') + " expected"
                            }) 
                          }
                        }
                      }
                      else{
                        //if public.allow is not array
                        diagnostics.push({
                              from: jsonMapObj.pointers['/'+el +'/'+pub].value.pos,
                              to: jsonMapObj.pointers['/'+el +'/'+pub].valueEnd.pos,
                              source: 'ai-model-validator',
                              severity: "error",
                              message: "Expected array"
                        }) 
                      }
                    }
                    if(pub==='inputs'){
                      inputsFlag = true
                      if(/* typeof(jsonMapObj.data[el][pub]) === 'object' */ Array.isArray(jsonMapObj.data[el][pub])){
                        //looping through public.inputs[]
                        let appNameTypeFlag = false
                        for(const [index,input] of jsonMapObj.data[el][pub].entries()){
                          let typeFlag, promptMacroFlag, nameFlag =false
                          for(const [i,inputKey] of Object.keys(input).entries()){
                            if(!Object.keys(this.keys.public.inputs).includes(inputKey)){
                              diagnostics.push({
                                from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].key.pos,
                                to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].keyEnd.pos,
                                source: 'ai-model-validator',
                                severity: "error",
                                message: "Invalid key"
                              }) 
                            }
                            if(inputKey=='promptMacro'){
                              promptMacroFlag = true
                              if(typeof(input[inputKey])!=='string'){
                                  diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].value.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].valueEnd.pos,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: "Invalid type, String expected"
                                })
                              }
                            }
                            if(inputKey=='name'){
                              nameFlag = true
                              if(typeof(input[inputKey])!=='string'){
                                  diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].value.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].valueEnd.pos,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: "Invalid type, String expected"
                                })
                              }
                            }
                            if(inputKey=='type'){
                              typeFlag = true
                              if(typeof(input[inputKey])!=='string'){
                                  diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].value.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].valueEnd.pos,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: "Invalid type, String expected"
                                })
                              }
                              else{
                                if(input[inputKey]=='app_name'){
                                  appNameTypeFlag = true
                                }
                                if(input[inputKey]!=='select' && input[inputKey]!=='text' && input[inputKey]!=='app_name' && input[inputKey]!=='list'){
                                  diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].value.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].valueEnd.pos,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: "Invalid value, select/text/app_name/list expected"
                                })
                                }
                              }
                            }
                            if(inputKey=='options'){
                              if(!Array.isArray(input[inputKey])){
                                  diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].value.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub+'/'+index+'/'+inputKey].valueEnd.pos,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: "Invalid type, Array expected"
                                })
                              }
                            }
                          } 
                          if(nameFlag == false || promptMacroFlag == false || typeFlag == false){
                            diagnostics.push({
                                  from: jsonMapObj.pointers['/'+el +'/'+pub].key.pos,
                                  to: jsonMapObj.pointers['/'+el +'/'+pub].keyEnd.pos+1,
                                  source: 'ai-model-validator',
                                  severity: "error",
                                  message: `missing 'name'/'promptMacro'/'type' keys`
                            })
                          }
                        }
                        if(appNameTypeFlag==false){
                          diagnostics.push({
                                from: jsonMapObj.pointers['/'+el +'/'+pub].value.pos,
                                to: jsonMapObj.pointers['/'+el +'/'+pub].valueEnd.pos+1,
                                source: 'ai-model-validator',
                                severity: "error",
                                message: "Input of type app_name is required."
                          })
                        }
                      }
                      else{
                        //if public.allow is not array
                        diagnostics.push({
                              from: jsonMapObj.pointers['/'+el +'/'+pub].value.pos,
                              to: jsonMapObj.pointers['/'+el +'/'+pub].valueEnd.pos,
                              source: 'ai-model-validator',
                              severity: "error",
                              message: "Expected array of object"
                        }) 
                      }
                    }
                    }
                    if(inputsFlag==false){
                      diagnostics.push({
                            from: jsonMapObj.pointers['/'+el ].value.pos,
                            to: jsonMapObj.pointers['/'+el ].valueEnd.pos+1,
                            source: 'ai-model-validator',
                            severity: "error",
                            message: "Inputs key is required."
                      })
                    }
                  }
                }
                if(el === 'properties'){
                  if(typeof(jsonMapObj.data[el])!== 'object' || Array.isArray(jsonMapObj.data[el])){
                    diagnostics.push({
                              from: jsonMapObj.pointers['/'+el].value.pos,
                              to: jsonMapObj.pointers['/'+el].valueEnd.pos,
                              source: 'ai-model-validator',
                              severity: "error",
                              message: "Invalid type, object expected"
                    }) 
                  }
                  else{
                    for(const propertyKey in jsonMapObj.data[el]){
                      if(!Object.keys(this.keys.properties).includes(propertyKey)){
                        diagnostics.push({
                        from: jsonMapObj.pointers['/'+el +'/'+propertyKey].key.pos,
                        to: jsonMapObj.pointers['/'+el +'/'+propertyKey].keyEnd.pos,
                        source: 'ai-model-validator',
                        severity: "error",
                        message: "Invalid key"
                        }) 
                      }
                    }
                  }
                }
              }
            }
            
          if(diagnostics.length) this.invalid = true;
          else this.invalid = false
          return diagnostics
        }        
      } catch (e) {
        this.invalid = true;
        if (!(e instanceof SyntaxError)) throw e
        const pos = this.getErrorPosition(e, view.state.doc)
        return [{
                from: pos,
                message: e.message,
                severity: 'error',
                to: pos
              }]
      }
      
      this.invalid = false;
      return []
    })

    this.startState = EditorState.create({
      doc: "",
      extensions: [basicSetup, jsonLang, keymap.of([...defaultKeymap, indentWithTab]), lintGutter(), inputListener, fixedHeightEditor, jsonLinter]
    });

    this.view = new EditorView({
      state: this.startState,
      parent: this.$refs.jsonEditor as Element,
    })
  }
},);
